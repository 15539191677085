var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tournament-item"},[_c('TournamentsList',{staticClass:"light-panel-header"},[_c('FilterCheckboxItem',{attrs:{"checkbox-id":"all-tournaments","checked":_vm.isAllCompetitionsChecked({ competitions: _vm.filteredTournamentsByEventCount }),"label":_vm.$t('menus.all'),"counter":_vm.tournamentsTotalCount},on:{"onCheck":function($event){return _vm.onAllCompetitionsCheck({ checked: $event, competitions: _vm.filteredTournamentsByEventCount })}}}),_vm._l((_vm.filteredTournamentsByEventCount),function(tournament,index){return _c('FilterCheckboxItem',{key:("tournament-" + (tournament.id) + "-" + index),attrs:{"checkbox-id":tournament.id,"checked":_vm.isCompetitionChecked({ competition: tournament }),"label":tournament.name,"counter":_vm.getEventsCount(tournament)},on:{"onCheck":function($event){return _vm.onCompetitionCheck({ checked: $event, competition: tournament })}}},[_c('template',{slot:"icon"},[(tournament.icon.src || tournament.icon.altSrc)?_c('ImageIcon',{staticClass:"tournament-icon icon-size-small",attrs:{"src":tournament.icon.src,"alt-src":tournament.icon.altSrc}}):_vm._e()],1)],2)})],2),_c('CountryMenus',{staticClass:"light-panel-header",attrs:{"event-count-key":_vm.eventCountKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var menu = ref.menu;
return _vm._l((_vm.getFilteredCompetitionsByEventCount(menu.data)),function(country,index){return _c('MenuItem',{key:((menu.slug) + "-country-" + (country.region.id) + "-" + index),staticClass:"sub-menu",attrs:{"selected":_vm.isExpandedCountry(country.region.id),"item":{
                    text: country.region.name,
                    control: {
                        text: _vm.getEventsCount(country),
                        icon: _vm.isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                    },
                }},on:{"onItemClick":function($event){return _vm.toggleCountry(country.region.id)}}},[_c('template',{slot:"icon"},[(country.icon.src || country.icon.altSrc)?_c('ImageIcon',{staticClass:"icon-status",attrs:{"src":country.icon.src,"alt-src":country.icon.altSrc}}):_vm._e()],1),_c('template',{slot:"content"},[(country.competitions)?_c('ExpansionPanel',{attrs:{"expanded":_vm.isExpandedCountry(country.region.id)}},[_c('FilterCheckboxItem',{attrs:{"checkbox-id":("all-" + (country.region.name) + "-" + (country.region.id)),"checked":_vm.isAllCompetitionsChecked({ competitions: country.competitions }),"label":_vm.$t('menus.all'),"counter":_vm.getEventsCount(country)},on:{"onCheck":function($event){return _vm.onAllCompetitionsCheck({ checked: $event, competitions: country.competitions })}}}),_vm._l((_vm.getFilteredCompetitionsByEventCount(country.competitions)),function(competition,competitionIndex){return _c('FilterCheckboxItem',{key:((menu.slug) + "-country-filter-" + (competition.slug) + "-" + competitionIndex),attrs:{"checkbox-id":("competition-" + competitionIndex + "-" + (country.region.id)),"checked":_vm.isCompetitionChecked({ competition: competition }),"label":competition.name,"counter":_vm.getEventsCount(competition)},on:{"onCheck":function($event){return _vm.onCompetitionCheck({ checked: $event, competition: competition })}}})})],2):_vm._e()],1)],2)})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }