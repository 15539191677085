<template>
    <div class="scrollable-content main-container" :class="{ 'disable-scroll': disableScroll }">
        <div ref="header" class="header">
            <slot name="section:top" />
        </div>
        <div class="sub-content">
            <div class="section grow-0">
                <div class="absolute-content content">
                    <slot name="section:left" />
                </div>
            </div>
            <div class="section" :class="['grow-6']">
                <div class="absolute-content flex-column content">
                    <slot name="section:center:top" />
                    <div class="section-middle flex-column">
                        <div v-if="isCenterOverlayActive" class="absolute-content overlay-content">
                            <slot name="section:center:middle:overlay" />
                        </div>
                        <div v-scroll="{ offset: ($refs.header && $refs.header.offsetHeight) || 0 }" class="scrollable-content flex-column">
                            <slot name="section:center:middle" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" :class="[$mq.isXMedium || hideRightSideBar ? 'grow-0' : 'grow-4']">
                <div class="absolute-content content">
                    <slot name="section:right" />
                </div>
            </div>
        </div>
        <slot name="section:bottom" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter } from '@agi.packages/platform';

export default {
    name: 'AppLayout',
    props: {
        isCenterOverlayActive: Boolean,
        disableScroll: Boolean,
        hideRightSideBar: Boolean,
    },
    computed: {
        ...mapGetters({
            brandPreference: getter.GET_BRAND_PREFERENCE,
        }),
    },
    mounted() {
        const { appFixedSizeBackground } = this.brandPreference;
        if (typeof appFixedSizeBackground === 'string') {
            document.body.style.setProperty('--app-fixed-size-background', appFixedSizeBackground);
        }
    },
};
</script>

<style lang="scss">
.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    display: flex;
    flex-direction: column;

    &.disable-scroll {
        overflow: hidden;
    }
}

.section {
    display: flex;
    flex-direction: column;

    $sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

    @each $size in $sizes {
        &.grow-#{$size} {
            flex-grow: $size;
            flex-shrink: $size;
        }
    }

    flex-grow: 1;
    position: relative;

    /* for Firefox */
    min-height: 0;

    &-middle {
        position: relative;
        overflow: hidden;
    }
}

.sub-content {
    display: flex;
    overflow: hidden;
    flex: 1;
}

.section-middle {
    position: relative;
    flex: 1;
    overflow: hidden;
}

.absolute-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.scrollable-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    @include hide_scrollbar;
}

.hidden {
    display: none;
}

$directions: column, row;

@each $dir in $directions {
    .flex-#{$dir} {
        display: flex;
        flex-direction: $dir;
    }
}
.overlay-content {
    z-index: 1;
}
</style>
