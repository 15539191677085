import { render, staticRenderFns } from "./MyBetsView.vue?vue&type=template&id=d25e6e6c&scoped=true"
import script from "./MyBetsView.vue?vue&type=script&lang=js"
export * from "./MyBetsView.vue?vue&type=script&lang=js"
import style0 from "./MyBetsView.vue?vue&type=style&index=0&id=d25e6e6c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d25e6e6c",
  null
  
)

export default component.exports