import Vue from 'vue';
import qs from 'qs';
import { action as coreAction, actionLoader, config, getJurisdictionHostname, deviceType, helper, userState } from '@agi.packages/core';
import {
    auth,
    authType,
    GTM_EVENT_SERVER_ERROR_MESSAGES,
    hasUserVerificationStatus,
    strapiPopulateFields,
    MAXIMUM_PAGE_SIZE,
    userVerificationStatuses,
    mutation as platformMutation,
    getter as platformGetter,
} from '@agi.packages/platform';
import { isSameDepositOption } from '@agi.packages/payment';
import { getter as translationsGetter, mutation as translationsMutation, action as translationsAction } from '@/store/modules/translations';
import { action as storeAction } from '@/store/store';

import { getLocalTypes } from '@/store/utils'; // to core utils BP-16141
import authModule from './store/modules/auth';
import messagingModule from './store/modules/messaging';
import { campaign, casino, cms, jurisdiction, ledger, pawapass, strapi, progressiveJackpots } from './endpoints';
import { strapiMenus } from './const/strapi';
import { flatDataAttributes } from '@/modules/core/utils/strapi/flatDataAttributes';
import { getParentPage } from '@/modules/core/utils/strapi/parentPage';
import { getActualHref, getActualLinkFrom } from '@/modules/core/utils/strapi/links';
import { getObjectField } from '@/modules/core/utils/helper';
import { formatStrapiStructure, getStrapiData } from '@/modules/core/utils/strapi/strapiData';
import { getAttribute } from '@/modules/core/utils/strapi/attribute';
import { strapiError404 } from '@/js/errors/strapiError404';
import { cmsError404 } from '@/js/errors/cmsError404';
import { SimplePageTemplateName } from '@/const/page-template-name';
import { getProviderOptionByProviderName } from '@/modules/platform/utils/getProviderOptionByProviderName';
import { progressiveJpType, progressiveJpTypes } from '@agi.packages/platform/const/progressive-jackpot';
import { buildEventsQueryParams } from '@agi.packages/sport/utils/api/build-events-query-params';
import { DEFAULT_TAKE, EventType } from '@/modules/sport';
import { timeDiffInDays } from '@/js/utils/dateTime/timeDiffInDays';
import { phoneNumberValidationByJurisdiction } from '@/modules/platform/utils/phoneNumberValidationByJurisdiction';
import { routePath } from '@/router/const-path';

const defaultPageCacheTime = 10 * 60 * 1000; // 10 minutes
const PAGE_CACHE_TIME = env.VUE_APP_PAGE_CACHE_TIME || defaultPageCacheTime;
const DEFAULT_CURRENCY_FORMAT = 'CURRENCY %s';
const CHIPS_CURRENCY_FORMAT = 'bPC %s';
const OLD_CMS_404_ERROR = 'CMS_PAGE_NOT_FOUND';
export const DEFAULT_PROGRESSIVE_JP_POLLING_TIME = 60 * 1000;

const SLOT_IDS_FOR_ERRORS = ['HOME_PAGE_COMPONENT', 'ERROR_PAGE_404', 'JACKPOT_RULES', 'JACKPOT_LUCKY_DIP_RULES', 'PAWABOOST_RULES'];

export const action = {
    CALL_ME_REQUEST: 'platform/callMeRequest',
    CHANGE_SITE_LANGUAGE: 'platform/changeSiteLanguage',
    CHECK_JURISDICTION: 'platform/checkJurisdiction',
    CHIPS_BALANCE_EXCHANGE: 'platform/chipsBalanceExchange',
    FETCH_REGIONS: 'platform/fetchRegions',
    GET_ALL_JURISDICTIONS: 'platform/getAllJurisdictions',
    GET_ANNOUNCEMENT: 'platform/getAnnouncement',
    GET_CALL_ME_DATA: 'platform/getCallMeData',
    GET_CAMPAIGN_ELIGIBILITY: 'platform/getCampaignEligibility',
    SET_USING_CAMPAIGN_ELIGIBILITY: 'platform/setUsingCampaignEligibility',
    GET_CASHIN_TAX_PREVIEW: 'platform/getCashInTaxPreview',
    GET_CONFIG: 'platform/getConfig',
    SET_CONFIG: 'platform/setConfig',
    GET_CONTENT: 'platform/getContent',
    GET_CONTENT_V2: 'platform/getContentV2',
    GET_DERIVED_DATA: 'platform/getDerivedData',

    GET_MODAL_WINDOW: 'platform/getModalWindow',
    GET_PAGE: 'platform/getPage',
    GET_PAGE_PREVIEW: 'platform/getPagePreview',
    GET_PAGE_V2: 'platform/getPageV2',
    GET_STATEMENT: 'platform/getStatement',
    GET_TEXT: 'platform/getText',
    LOAD_AGI_SETTINGS: 'platform/loadAgiSettings',
    GET_COMPONENT_DATA: 'platform/getComponentData',
    PAWAPASS_VERIFICATION: 'platform/pawapassVerification',
    PUT_DERIVED_DATA: 'platform/putDerivedData',
    REFRESH_CONTENT: 'platform/refreshContent',
    RESET_STATEMENT: 'platform/resetStatement',
    SET_AFFILIATE_ID: 'platform/setAffiliateId',
    SET_NEW_BADGE_COUNT: 'platform/putNewBadgeCount',
    TRACK_USER_DATA: 'platform/trackUserData',
    TRACK_USER_PRESENCE: 'platform/trackUserPresence',
    GET_CHIPS_TOP_UP_TAX_PREVIEW: 'platform/getTopUpTaxPreview',
    GET_CHIPS_CASH_IN_TAX_PREVIEW: 'platform/getCashInTaxPreview',
    UPDATE_LANGUAGE_PREFERENCE: 'platform/updateLanguagePreference',
    POLL_ACTIVE_PROGRESSIVE_JACKPOTS: 'platform/pollActiveProgressiveJackpots',
    GET_ACTIVE_PROGRESSIVE_JACKPOTS: 'platform/getActiveProgressiveJackpots',
    GET_PROGRESSIVE_JACKPOT_WINNERS: 'platform/getProgressiveJackpotWinners',
};

export const mutation = {
    ACTIVATE_GTM: 'platform/activateGTM',
    APPEND_AGI_SETTINGS: 'platform/appendAgiSettings',
    RESET_CHIPS_ERROR: 'platform/resetChipsError',
    RESET_KYC: 'platform/resetKycStatus',
    RESET_STATEMENT: 'platform/resetStatement',
    SET_AFFILIATE_ID: 'platform/setAffiliateId',
    SET_AGI_SETTINGS: 'platform/setAgiSettings',
    SET_SETTINGS_ERROR: 'platform/setSettingsError',
    SET_ALL_JURISDICTIONS: 'platform/setAllJurisdictions',
    SET_ANNOUNCEMENT: 'platform/setAnnouncement',
    SET_CALL_ME_DATA: 'platform/setCallMeData',
    SET_CALL_ME_STATUS: 'platform/setCallMeStatus',
    SET_CAMPAIGN_ELIGIBILITY: 'platform/setCampaignEligibility',
    SET_USING_CAMPAIGN_ELIGIBILITY: 'platform/setUsingCampaignEligibility',
    SET_CHIPS_ERROR: 'platform/setChipsError',
    SET_CHIPS_CASH_IN_TAX_DETAILS: 'platform/setChipsCashInTaxDetails',
    SET_CHIPS_TOP_UP_TAX_DETAILS: 'platform/setChipsTopUpTaxDetails',
    SET_CONFIG: 'platform/setConfig',
    SET_COUNTRY_CODE_IS: 'platform/setCountryCodeIs',
    SET_FAVORITE_CASINO_GAME: 'platform/setFavoriteCasinoGame',
    SET_FORCE_GET_CONTENT: 'platform/setForceGetContent',
    SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT: 'platform/setIsNeedToSendPaymentComponentVisibilityEvent',
    SET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED: 'platform/setIsPaymentComponentVisibilityRulePassed',
    SET_KYC: 'platform/setKycStatus',
    SET_NEW_BADGE_COUNT: 'platform/setNewBadgeCount',
    SET_REGIONS: 'platform/setRegions',
    SET_STATEMENT: 'platform/setStatement',
    SET_SURVEY: 'platform/setSurvey',
    SET_USER_STATE: 'platform/setUserState',
    UPDATE_PREFERENCE: 'platform/updatePreference',
    SET_ACTIVE_PROGRESSIVE_JACKPOTS: 'platform/setActiveProgressiveJackpots',
    SET_ACTIVE_PROGRESSIVE_JACKPOT_POLLING: 'platform/setActiveProgressiveJackpotPolling',
    CLEAR_ACTIVE_PROGRESSIVE_JACKPOT_POLLING: 'platform/clearActiveProgressiveJackpotPolling',
    SET_PROGRESSIVE_JACKPOT_WINNERS: 'platform/setProgressiveJackpotWinners',
    SET_LAST_SEEN_WIN_BET_ID: 'platform/setLastSeenWinBetId',
};

export const getter = {
    GET_BEST_ODDS_LABEL: 'platform/getBestOddsLabel',
    GET_BRAND: 'platform/getBrand',
    GET_BRAND_DETAILS: 'platform/getBrandDetails',
    GET_BRAND_PREFERENCE: 'platform/getBrandPreference',
    GET_BIG_WIN: 'platform/getBigWin',
    GET_CAMPAIGN_ELIGIBILITY: 'platform/getCampaignEligibility',
    GET_IS_USING_CAMPAIGN_ELIGIBILITY: 'platform/getIsUsingCampaignEligibility',
    GET_CONTENT: 'platform/getContent',
    GET_CONTENT_SLOTS: 'platform/getContentSlots',
    GET_COUNTRY: 'platform/getCountry',
    COUNTRY_CODE_IS: 'platform/countryCodeIs',
    GET_CURRENT_USER_STATUS: 'platform/getCurrentUserStatus',
    GET_DATE_OPTIONS: 'platform/getDateOptions',
    GET_DEPOSIT_OPTIONS_CONFIG: 'platform/getDepositOptionsConfig',
    GET_DEPOSIT_OPTION_BY_PHONE_OPERATOR: 'platform/getDepositOptionByPhoneOperator',
    GET_DEPOSIT_OPTION_BY_PREFERRED_PAYMENT_PROVIDER: 'platform/getDepositOptionByPreferredPaymentProvider',
    GET_DEPOSIT_PAGE_LINK: 'platform/getDepositPageLink',
    GET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT: 'platform/getIsNeedToSendPaymentComponentVisibilityEvent',
    GET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED: 'platform/getIsPaymentComponentVisibilityRulePassed',
    GET_MINIMUM_ODDS_FOR_BONUS: 'platform/getMinimumOddsForBonus',
    GET_PAGE: 'platform/getPage',
    GET_PAGES: 'platform/getPages',
    GET_POOL_TAX_AMOUNT: 'platform/getPoolTaxAmount',
    GET_PREFERENCE: 'platform/getPreference',
    GET_SETTINGS: 'platform/getSettings',
    GET_SOCKETS_URL: 'platform/getSocketsUrl',
    GET_STAKE_TAX_AMOUNT: 'platform/getStakeTaxAmount',
    GET_SURVEY: 'platform/getSurvey',
    GET_TAX_AMOUNT: 'platform/getTaxAmount',
    GET_TAX_COMPENSATE_RATE: 'platform/getTaxCompensate',
    GET_VIRTUAL_TAX_COMPENSATE_RATE: 'platform/getVirtualTaxCompensate',
    GET_TEXT: 'platform/getText',
    GET_USER_SETTINGS: 'platform/getUserSettings',
    GET_VIRTUAL_STAKE_TAX_AMOUNT: 'platform/getVirtualStakeTaxAmount',
    GET_VIRTUAL_TAX_AMOUNT: 'platform/getVirtualTaxAmount',
    GET_CASH_IN_TAX_DETAILS: 'platform/getCashInTaxDetails',
    GET_TOP_UP_TAX_DETAILS: 'platform/getTopUpTaxDetails',
    IS_AUTO_CASHOUT_ENABLED: 'platform/isAutoCashOutEnabled',
    GET_JURISDICTIONS: 'platform/getJurisdictions',
    IS_BONUS_SCHEMA: 'platform/isBonusSchema',
    IS_EVENT_PAGE_SOCKETS_ENABLED: 'platform/isEventPageSocketsEnabled',
    IS_FORCE_GET_CONTENT: 'platform/isForceGetContent',
    IS_KYC_VERIFIED_OR_BYPASS: 'platform/isKycVerifiedOrBypass',
    IS_MULTIPLIER_SCHEMA: 'platform/isMultiplierSchema',
    IS_OVERTHRESHOLD: 'platform/isOverThreshold',
    IS_POOL_TAX_ENABLED: 'platform/isPoolTaxEnabled',
    IS_TAX_ENABLED: 'platform/isTaxEnabled',
    IS_VIRTUAL_ENABLED: 'platform/isVirtualEnabled',
    IS_VIRTUAL_TAX_ENABLED: 'platform/isVirtualTaxEnabled',
    IS_VIRTUAL_WIN_TAX_ENABLED: 'platform/isVirtualWinTaxEnabled',
    IS_WIN_TAX_ENABLED: 'platform/isWinTaxEnabled',
    GET_CATEGORIES: 'platform/getCategories',
    GET_SPORTSBOOK_TABS_CONFIGURATION: 'platform/getSportsbookTabsConfiguration',
    GET_JURISDICTION_CONFIG: 'platform/getJurisdictionConfig',
    IS_CASHOUT_AVAILABLE: 'platform/isCashOutAvailable',
    GET_CASHOUT_AVAILABILITY_TIME: 'platform/getCashOutAvailabilityTime',
    GET_MARKET_TYPE_FILTERS: 'platform/getMarketTypeFilters',
    GET_PROGRESSIVE_JP: 'platform/getProgressiveJP',
    GET_CASINO_TAX_AMOUNT: 'platform/getCasinoTaxAmount',
    GET_SPORT_PROGRESSIVE_JP_FROM_SETTINGS: 'platform/getSportProgressiveJpFromSettings',
    GET_LAST_WIN_INFO: 'platform/getLastWinInfo',
    GET_LAST_SEEN_WIN_BET_ID: 'platform/getLastSeenWinBetId',
    IS_BIG_WIN_NOTIFICATION_DISABLED: 'platform/isBigWinNotificationDisabled',
    GET_AGE_LIMIT_REGULATION_CONTENT: 'platform/getAgeLimitRegulationInfo',
    GET_PHONE_NUMBER_VALIDATION: 'platform/getPhoneNumberValidation',
    GET_SIDE_BAR_DISABLED_PAGES: 'platform/getSideBarDisabledPages',
};

const SETTINGS_STATE = {
    currency: {},
    jurisdiction: {},
    bonus: {},
    moduleSwitches: {},
    user: {},
    betting: {},
    cashout: {},
    error: null,
    payment: {},
    pool: {},
    preference: {
        kycbypass: null,
        kycverified: null,
        favorite_casino_games: '',
        favorite_casino_games_ids: null,
        favorite_strapi_casino_games_ids: null,
    },
    brand: {},
    virtuals: {},
    sportsbook: {
        category: [],
    },
    sportsbookConfigurator: {
        marketTypeFilters: [],
        tabs: [],
        taxBrand: {},
    },
};

const STATEMENT_STATE = {
    hasMoreResults: false,
    nextPageId: null,
    transactions: [],
    error: null,
};

const PROGRESSIVE_JP_TYPE_STATE = {
    active: [],
    winners: [],
};

const PROGRESSIVE_JP_STATE = progressiveJpTypes.reduce((result, type) => {
    result[type] = { ...PROGRESSIVE_JP_TYPE_STATE };
    return result;
}, {});

const state = {
    config: {},
    settings: { ...SETTINGS_STATE },
    content: {
        announcement: [],
        text: {},
        slots: {},
        pages: {},
        error: {
            message: null,
            statusCode: null,
        },
        forceGetContent: false,
        isPaymentComponentVisibilityRulePassed: false,
        isNeedToSendPaymentComponentVisibilityEvent: true,
    },
    regions: [],
    callMeRequest: {
        successMessage: null,
        errorMessage: null,
    },
    callMeData: {
        estimatedWaitingTimeMin: null,
        callMeCategories: [],
    },
    affiliate: {
        id: null,
    },
    chips: {
        cashInTaxDetails: {},
        topUpTaxDetails: {},
        errorMessage: null,
    },
    statement: { ...STATEMENT_STATE },
    campaignEligibility: {},
    isUsingCampaignEligibility: false,
    gtmActive: false,
    survey: {},
    countryCodeIs: {},
    jurisdictions: [],
    progressiveJackpots: {
        ...PROGRESSIVE_JP_STATE,
        pollingId: null,
        lastRequestTimestamp: null,
    },
};

const _getter = getLocalTypes(getter);

const getters = {
    [_getter.GET_SETTINGS]: (state) => state.settings,
    [_getter.COUNTRY_CODE_IS]: (state) => state.countryCodeIs,
    [_getter.GET_PREFERENCE]: (state) => state.settings.preference,
    [_getter.GET_BRAND_PREFERENCE]: (state) => ({ ...(state.settings.brand.preference || {}), ...(state.config.brandPreferences || {}) }),
    [_getter.GET_BIG_WIN]: (state) => {
        const bigWinData = getObjectField(state.config, 'bigWin.data');

        if (!bigWinData) return {};

        const bigWin = flatDataAttributes(bigWinData);
        bigWin.seoImage = getAttribute('url')(bigWin.seoImage);
        bigWin.tiers = bigWin.tiers.map((tier) => {
            const { backgroundImg, backgroundImgMobile, trophyImg } = tier;
            return {
                ...tier,
                backgroundImg: getAttribute('url')(backgroundImg),
                backgroundImgMobile: getAttribute('url')(backgroundImgMobile),
                trophyImg: getAttribute('url')(trophyImg),
            };
        });

        return bigWin;
    },
    [_getter.GET_SOCKETS_URL]: (state, getters) => {
        return getters[_getter.GET_BRAND_PREFERENCE]?.socketsUrl;
    },
    [_getter.IS_EVENT_PAGE_SOCKETS_ENABLED]: (state, getters) => {
        return !!getters[_getter.GET_BRAND_PREFERENCE]?.isEventPageSocketsEnabled;
    },
    getUserSettings: (state) => state.settings.user,
    [_getter.GET_DATE_OPTIONS]: (state, getters) => {
        const { dateFormat, timeZoneOffset } = getters[_getter.GET_BRAND_PREFERENCE];
        const weekDays = Vue.$t('weekDays');
        return { hour12: dateFormat === '12', timeZoneOffset, weekDays };
    },
    getText: (state) => state.content.text,
    [_getter.GET_CONTENT_SLOTS]: (state) => state.content.slots,
    getPages: (state) => state.content.pages,
    isForceGetContent: (state) => state.content.forceGetContent,
    isMultiplierSchema: (state) => state.settings.bonus.type === 'ODDS_MULTIPLIER',
    isBonusSchema: (state) => state.settings.bonus.type === 'BONUS',
    [_getter.IS_TAX_ENABLED]: ({ settings }) => !!settings?.taxBrand?.real?.stake && !!Object.keys(settings.taxBrand.real.stake).length,
    [_getter.IS_WIN_TAX_ENABLED]: ({ settings }) => !!settings?.taxBrand?.real?.win && !!Object.keys(settings.taxBrand.real.win).length,
    [_getter.IS_VIRTUAL_TAX_ENABLED]: (state) =>
        !!state.settings?.taxBrand?.virtual?.stake && !!Object.keys(state.settings.taxBrand.virtual.stake).length,
    [_getter.IS_VIRTUAL_WIN_TAX_ENABLED]: (state) =>
        !!state.settings?.taxBrand?.virtual?.win && !!Object.keys(state.settings.taxBrand.virtual.win).length,
    isPoolTaxEnabled: (state) => !!state.settings?.taxBrand?.pool && !!Object.keys(state.settings.taxBrand.pool).length,
    getTaxAmount: (state) => {
        const { rate } = getObjectField(state, 'settings.taxBrand.real.win.rates', [])[0] || {};
        return rate || 0;
    },
    [_getter.GET_TAX_COMPENSATE_RATE]: (state) => {
        return getObjectField(state, 'settings.taxBrand.real.win.taxCompensate.rate', 0);
    },
    [_getter.GET_VIRTUAL_TAX_COMPENSATE_RATE]: (state) => {
        return getObjectField(state, 'settings.taxBrand.virtual.win.taxCompensate.rate', 0);
    },
    getVirtualTaxAmount: (state) => state.settings.taxBrand?.virtual?.win?.rates[0]?.rate || 0,
    getPoolTaxAmount: (state) => state.settings.taxBrand?.pool?.win?.rates[0]?.rate || 0,
    [_getter.GET_STAKE_TAX_AMOUNT]: (state) => state.settings.taxBrand?.real?.stake?.rate || 0,
    [_getter.GET_VIRTUAL_STAKE_TAX_AMOUNT]: (state) => state.settings.taxBrand?.virtual?.stake?.rate || 0,
    isVirtualEnabled: (state) => state.settings.virtuals.enabled,
    [_getter.GET_CURRENT_USER_STATUS]: ({ auth, settings }) => {
        const { isAuthenticated, accountBalance } = auth;
        const { user, preference, betting } = settings;
        const { first_bet: firstBetSlip, deposits_made: depositsMade, avg_weighted_stake: avgWeightedStake } = preference;
        const minStake = betting.real?.minStake || 0;
        const avgWgtStake = avgWeightedStake || 0;
        const isOverThreshold = accountBalance > avgWgtStake;
        const hasMoney = accountBalance > 0;
        const isBalanceUnderMinStake = accountBalance < minStake;
        const isEverDeposited = !!depositsMade || accountBalance > 0 || !!firstBetSlip;

        return {
            [userState.NOT_LOGGED_IN]: !isAuthenticated,
            [userState.LOGGED_IN]: isAuthenticated,
            [userState.LOGGED_IN_NEVER_DEPOSITED]: isAuthenticated && user.userUuid && !isEverDeposited,
            [userState.LOGGED_IN_OUT_OF_MONEY]: isAuthenticated && !isOverThreshold && user.userUuid && isEverDeposited,
            [userState.LOGGED_IN_OUT_OF_MONEY_ANY_DEPOSITED]: isAuthenticated && isBalanceUnderMinStake,
            [userState.LOGGED_IN_LOT_OF_MONEY]: isAuthenticated && isOverThreshold,
            [userState.LOGGED_IN_HAS_MONEY]: isAuthenticated && hasMoney,
            [userState.EVER_DEPOSITED]: isEverDeposited,
        };
    },
    [_getter.GET_BRAND]: (state) => state.settings?.brand || {},
    [_getter.GET_BRAND_DETAILS]: (state, getters) => {
        const brand = getters[_getter.GET_BRAND];
        return brand.details || {};
    },
    [_getter.GET_COUNTRY]: (state, getters) => {
        const { isoCountryCode } = getters[_getter.GET_BRAND_DETAILS];
        const { countryCode } = getters[_getter.GET_BRAND_PREFERENCE];
        return isoCountryCode || countryCode || '';
    },
    isKycVerifiedOrBypass: (state) => {
        const { kycbypass, kycverified } = state.settings.preference;
        return (kycbypass || kycverified) && { kycbypass, kycverified };
    },
    isOverThreshold: ({ settings, auth }) => {
        const { accountBalance } = auth;
        const avgWgtStake = settings.preference.avg_weighted_stake || 0;
        return accountBalance > avgWgtStake;
    },
    [_getter.GET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED]: (state) => state.content.isPaymentComponentVisibilityRulePassed,
    [_getter.GET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT]: (state) => state.content.isNeedToSendPaymentComponentVisibilityEvent,
    [_getter.GET_DEPOSIT_OPTIONS_CONFIG]: (state) => helper.getObjectField(state, 'config.depositOptions', {}), // TODO: Refactor https://aliengain.atlassian.net/browse/BP-30003
    [_getter.GET_DEPOSIT_OPTION_BY_PHONE_OPERATOR]: (state, getters) => {
        const { phoneOperatorNetwork } = getters[_getter.GET_USER_SETTINGS];
        return getProviderOptionByProviderName(phoneOperatorNetwork, getters[_getter.GET_DEPOSIT_OPTIONS_CONFIG]);
    },
    [_getter.GET_DEPOSIT_OPTION_BY_PREFERRED_PAYMENT_PROVIDER]: (state, getters) => {
        const { preferred_payment_provider: preferredPaymentProvider } = getters[_getter.GET_PREFERENCE];
        return getProviderOptionByProviderName(preferredPaymentProvider, getters[_getter.GET_DEPOSIT_OPTIONS_CONFIG]);
    },
    getDepositPageLink: (state, getters, rootState, rootGetters) => {
        const config = getters[_getter.GET_DEPOSIT_OPTIONS_CONFIG];
        const pref = getters[_getter.GET_SETTINGS]?.preference;
        const { deposit_option: lastDepositOption } = pref || {};
        let resultUrl = null;
        if (config?.data && lastDepositOption) {
            const optionConfig = config.data.find((item) => isSameDepositOption(item.attributes, { depositTypeName: lastDepositOption }));

            if (optionConfig) {
                const { ussdAvailable, webAvailable, page } = optionConfig.attributes || {};
                let link = page && getActualHref(page);

                if (link) {
                    if (ussdAvailable && !webAvailable) {
                        link = `${link}-ussd`;
                    }
                    if (link.startsWith('/')) {
                        resultUrl = link;
                    } else {
                        resultUrl = `/${link}`;
                    }
                }
            }
        }
        return resultUrl;
    },
    [_getter.GET_BEST_ODDS_LABEL]: (state) => state.config.bestOddsLabel || Vue.$t('ui.common.bestOdds'),
    [_getter.GET_CAMPAIGN_ELIGIBILITY]: (state) => state.campaignEligibility,
    [_getter.GET_IS_USING_CAMPAIGN_ELIGIBILITY]: (state) => state.isUsingCampaignEligibility,
    [_getter.GET_SURVEY]: (state) => state.survey,
    [_getter.GET_MINIMUM_ODDS_FOR_BONUS]: (state) => Number(state.settings.bonus.minimumOdds),
    [_getter.GET_CASH_IN_TAX_DETAILS]: (state) => state.chips.cashInTaxDetails,
    [_getter.GET_TOP_UP_TAX_DETAILS]: (state) => state.chips.topUpTaxDetails,
    [_getter.GET_CATEGORIES]: (state) => state.settings.sportsbook.category,
    [_getter.GET_SPORTSBOOK_TABS_CONFIGURATION]: (state) => state.settings.sportsbookConfigurator.tabs ?? [],
    [_getter.GET_JURISDICTION_CONFIG]: (state) => state.config || {},
    [_getter.IS_AUTO_CASHOUT_ENABLED]: (state) => getObjectField(state, 'settings.autoCashout.enabled', false),
    [_getter.IS_CASHOUT_AVAILABLE]: (state) => getObjectField(state, 'settings.cashout.available', true),
    [_getter.GET_CASHOUT_AVAILABILITY_TIME]: (state) => getObjectField(state, 'settings.cashout.availability'),
    [_getter.GET_JURISDICTIONS]: (state) => state.jurisdictions,
    [_getter.GET_MARKET_TYPE_FILTERS]: (state) => getObjectField(state, 'settings.sportsbookConfigurator.marketTypeFilters', []),
    [_getter.GET_PROGRESSIVE_JP]: (state) => getObjectField(state, 'progressiveJackpots'),
    [_getter.GET_CASINO_TAX_AMOUNT]: (state) => {
        const { rate } = getObjectField(state, 'settings.taxBrand.casino.win.rates', [])[0] || {};
        return rate || 0;
    },
    [_getter.GET_SPORT_PROGRESSIVE_JP_FROM_SETTINGS]: (state) => {
        const progressiveJackpot = getObjectField(state, 'settings.progressiveJackpot');
        return Array.isArray(progressiveJackpot)
            ? progressiveJackpot.find((jackpot) => jackpot.type.toLowerCase() === progressiveJpType.SPORT)
            : undefined;
    },
    [_getter.GET_LAST_WIN_INFO]: (state) => {
        const lastWinInfo = {
            lastWinBetPrice: state.settings.preference.last_win_bet_price,
            lastWinBetPayout: state.settings.preference.last_win_bet_payout,
            lastWinBetId: state.settings.preference.last_win_bet_id,
        };
        return Object.values(lastWinInfo).every((info) => !!info) ? lastWinInfo : null;
    },
    [_getter.IS_BIG_WIN_NOTIFICATION_DISABLED]: (state) => {
        const { disable_big_win_notification_date: disableBigWInNotificationDate } = state.settings.preference;
        const notificationDisablePeriodDays = getObjectField(state.config.bigWin, 'notificationDisablePeriodDays', null);

        return !disableBigWInNotificationDate
            ? false
            : timeDiffInDays(disableBigWInNotificationDate, new Date()) < +notificationDisablePeriodDays;
    },
    [_getter.GET_LAST_SEEN_WIN_BET_ID]: (state) => {
        return getObjectField(state.settings.preference, 'last_seen_win_bet_id');
    },
    [_getter.GET_AGE_LIMIT_REGULATION_CONTENT]: (state) => {
        return state.config.ageLimitRegulationContent
            ? {
                  ...state.config.ageLimitRegulationContent,
                  icon: getObjectField(state.config, 'ageLimitRegulationContent.icon.data.attributes.url'),
              }
            : null;
    },
    [_getter.GET_PHONE_NUMBER_VALIDATION]: (state, getters) => {
        const { isNewPhoneNumberTransitionPeriod } = getters[_getter.GET_BRAND_PREFERENCE];
        const countryCode = getters[_getter.GET_COUNTRY];
        return isNewPhoneNumberTransitionPeriod ? phoneNumberValidationByJurisdiction[countryCode] : undefined;
    },
    [_getter.GET_SIDE_BAR_DISABLED_PAGES]: (state) => {
        const sidebarDisabledPages = state.config.sidebarDisabledPages || [];
        return sidebarDisabledPages.map(({ path }) => path);
    },
};

const _mutation = getLocalTypes(mutation);

const mutations = {
    [_mutation.SET_AGI_SETTINGS](state, value) {
        const {
            brand: {
                details: { currency, defaultCurrency },
                preference: { currencyFormat, symbolFallback },
            },
            user,
        } = value;
        const format = currencyFormat || DEFAULT_CURRENCY_FORMAT;
        const chips = currency.find((item) => item !== defaultCurrency);
        const isLoggedIn = !!user?.userUuid;
        // TODO: replace mapState "state.platform.settings.currency.format" with currency getter
        value.currency = {
            code: defaultCurrency,
            symbolFallback,
            platformCode: currency[0], // TODO: platformCode used only in unit test, maybe replace "platformCode" with "code"
            format,
            symbol: helper.formatCurrencySymbol(format),
        };

        if (chips) {
            value.chips = {
                currency: {
                    platformCode: chips,
                    format: CHIPS_CURRENCY_FORMAT,
                    symbol: helper.formatCurrencySymbol(CHIPS_CURRENCY_FORMAT),
                },
            };
        }

        value.betting.real.minStake = calculateMinStakeWithTax(getObjectField(value.taxBrand, 'real.stake.rate'), value.betting.real);

        value.betting.virtuals.minStake = calculateMinStakeWithTax(
            getObjectField(value.taxBrand, 'virtual.stake.rate'),
            value.betting.virtuals
        );

        if (value.bonus?.maxLegs && value.bonus?.perLeg?.length) {
            const emptyLegs = new Array(value.bonus.maxLegs - value.bonus.perLeg.length + 1).fill(0);
            value.bonus.perLeg = [...emptyLegs, ...value.bonus.perLeg];
        }

        const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
        state.settings = helper.deepMerge(state.settings, { ...(isLoggedIn && SETTINGS_STATE), ...value }, { arrayMerge: overwriteMerge });

        window.agi_settings_user_id = value.user?.userUuid;
    },
    appendAgiSettings(state, value) {
        const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
        state.settings = helper.deepMerge(state.settings, value, { arrayMerge: overwriteMerge });
    },
    [_mutation.SET_SETTINGS_ERROR](state, error) {
        state.settings.error = error;
    },
    setContentSlots(state, payload) {
        state.content.slots = { ...state.content.slots, ...payload };
    },
    setSlotIds(state, payload) {
        // legacy?
        state.content.slotIds = payload;
    },
    setPageId(state, payload) {
        // legacy?
        state.content.pageId = payload;
    },
    setPage(state, payload) {
        payload.data.timestamp = new Date();
        Vue.set(state.content.pages, payload.path, payload.data);
    },
    [_mutation.SET_CONFIG](state, payload) {
        state.config = { ...state.config, ...payload };
    },
    [_mutation.SET_NEW_BADGE_COUNT](state, payload) {
        Vue.set(state.settings.preference, payload.key, Number(payload.value) || 0);
    },
    setContentText(state, payload) {
        Vue.set(state.content.text, payload.id, payload.data);
    },
    setContentError(state, payload) {
        state.content.error = payload;
    },
    removeContentError(state) {
        state.content.error.message = null;
        state.content.error.statusCode = null;
    },
    setStatementError(state, payload) {
        state.statement.error = payload;
    },
    [_mutation.SET_FAVORITE_CASINO_GAME](state, payload) {
        state.settings.preference.favorite_casino_games_ids = payload;
    },
    [_mutation.SET_KYC](state, payload) {
        const { success, bypass } = payload || {};
        state.settings.preference.kycverified = success;
        state.settings.preference.kycbypass = bypass;
    },
    [_mutation.RESET_KYC](state) {
        state.settings.preference.kycverified = null;
        state.settings.preference.kycbypass = null;
    },
    [_mutation.SET_REGIONS](state, payload) {
        const { capital } = getters[_getter.GET_BRAND_PREFERENCE];
        payload.some((region, idx) => region.name === capital && payload.unshift(payload.splice(idx, 1)[0]));
        state.regions = payload;
    },
    [_mutation.SET_USER_STATE](state, payload) {
        state.settings.user = { ...state.settings.user, ...payload };
    },
    [_mutation.SET_CALL_ME_STATUS](state, { errorMessage = null, successMessage = null }) {
        state.callMeRequest.successMessage = successMessage;
        state.callMeRequest.errorMessage = errorMessage;
    },
    [_mutation.SET_CALL_ME_DATA](state, { estimatedWaitingTimeMin = null, callMeCategories = [] }) {
        state.callMeData.estimatedWaitingTimeMin = estimatedWaitingTimeMin;
        state.callMeData.callMeCategories = callMeCategories;
    },
    [_mutation.SET_ANNOUNCEMENT](state, announcement) {
        state.content.announcement = announcement;
    },
    [_mutation.SET_AFFILIATE_ID](state, payload) {
        state.affiliate.id = payload;
    },
    [_mutation.SET_STATEMENT](state, { hasMoreResults, transactions, nextPageId }) {
        state.statement.hasMoreResults = hasMoreResults;
        state.statement.nextPageId = nextPageId;
        state.statement.transactions = [...state.statement.transactions, ...transactions];
    },
    [_mutation.RESET_STATEMENT](state) {
        state.statement = { ...STATEMENT_STATE };
    },
    [_mutation.ACTIVATE_GTM](state) {
        state.gtmActive = true;
    },
    [_mutation.SET_FORCE_GET_CONTENT](state, value) {
        state.content.forceGetContent = value;
    },
    [_mutation.SET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED](state, value) {
        state.content.isPaymentComponentVisibilityRulePassed = value;
    },
    [_mutation.SET_IS_NEED_TO_SEND_PAYMENT_COMPONENT_VISIBILITY_EVENT](state, value) {
        state.content.isNeedToSendPaymentComponentVisibilityEvent = value;
    },
    [_mutation.UPDATE_PREFERENCE](state, payload) {
        state.settings.preference = { ...state.settings.preference, ...payload };
    },
    [_mutation.SET_CAMPAIGN_ELIGIBILITY](state, payload = {}) {
        state.campaignEligibility = payload;
    },
    [_mutation.SET_USING_CAMPAIGN_ELIGIBILITY](state, value) {
        state.isUsingCampaignEligibility = value;
    },
    [_mutation.SET_CHIPS_CASH_IN_TAX_DETAILS](state, value) {
        state.chips.cashInTaxDetails = value;
    },
    [_mutation.SET_CHIPS_TOP_UP_TAX_DETAILS](state, value) {
        state.chips.topUpTaxDetails = value;
    },
    [_mutation.SET_CHIPS_ERROR](state, { errorMessage = null }) {
        state.chips.errorMessage = errorMessage;
    },
    [_mutation.RESET_CHIPS_ERROR](state) {
        state.chips.errorMessage = null;
    },
    [_mutation.SET_SURVEY](state, value) {
        state.survey = value;
    },
    [_mutation.SET_COUNTRY_CODE_IS](state, { countryCode }) {
        state.countryCodeIs = {
            [countryCode]: true,
        };
    },
    [_mutation.SET_ALL_JURISDICTIONS](state, payload) {
        state.jurisdictions = formatStrapiStructure(payload).map(
            ({ countryCode, brandPreferences: { countryName, brandName, countryFlag } }) => ({
                countryCode,
                countryName,
                brandName,
                countryFlag: getAttribute('url')(countryFlag),
            })
        );
    },
    [_mutation.SET_ACTIVE_PROGRESSIVE_JACKPOTS](state, jackpots) {
        Object.entries(collectProgressiveJPByType(jackpots)).forEach(([key, val]) => {
            Vue.set(state.progressiveJackpots[key], 'active', val);
        });
        Vue.set(state.progressiveJackpots, 'lastRequestTimestamp', new Date().getTime());
    },
    [_mutation.SET_ACTIVE_PROGRESSIVE_JACKPOT_POLLING](state, pollingId) {
        Vue.set(state.progressiveJackpots, 'pollingId', pollingId);
    },
    [_mutation.CLEAR_ACTIVE_PROGRESSIVE_JACKPOT_POLLING](state) {
        const { pollingId } = state.progressiveJackpots;
        pollingId && clearInterval(pollingId);
        Vue.set(state.progressiveJackpots, 'pollingId', null);
    },
    [_mutation.SET_PROGRESSIVE_JACKPOT_WINNERS](state, winners) {
        Object.entries(collectProgressiveJPByType(winners, 'type')).forEach(([key, val]) => {
            Vue.set(state.progressiveJackpots[key], 'winners', val);
        });
    },
    [_mutation.SET_LAST_SEEN_WIN_BET_ID](state, betId) {
        Vue.set(state.settings.preference, 'last_seen_win_bet_id', betId);
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.GET_COMPONENT_DATA]: () => {
        return Vue.$http
            .get(cms.getComponentDatav1)
            .then(({ data }) => data)
            .catch((error) => {
                const { errorCode, statusCode, config = '' } = error;
                const code = errorCode || statusCode;
                if (GTM_EVENT_SERVER_ERROR_MESSAGES[code] || !code) {
                    Vue.$gtm.query({
                        event: GTM_EVENT_SERVER_ERROR_MESSAGES[code] || GTM_EVENT_SERVER_ERROR_MESSAGES['component_data'],
                        url: config.url,
                    });
                }
                throw helper.processErrorResponse(error, 'critical.error.appSomethingWentWrong');
            });
    },
    [_action.LOAD_AGI_SETTINGS]: actionLoader(action.LOAD_AGI_SETTINGS, ({ dispatch, commit, getters, rootGetters }, payload) => {
        const { preload, noUserTracking, authType: payloadAuthType, keepLoggedIn } = payload || {};
        return dispatch(
            coreAction.LOADER,
            [
                preload ?? {},
                (resolve, reject) =>
                    dispatch(_action.GET_COMPONENT_DATA)
                        .then((data) => resolve(data))
                        .catch((error) => reject(error)),
            ],
            { root: true }
        )
            .then((data) => {
                const { user, preference } = data;
                commit(_mutation.SET_AGI_SETTINGS, data);

                dispatch(_action.TRACK_USER_PRESENCE);
                !noUserTracking && dispatch(_action.TRACK_USER_DATA);

                dispatch(_action.GET_ANNOUNCEMENT);

                /**
                 * If Logged in
                 * AND preferred language don't match user selected
                 */
                const selectedLanguage = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
                if (preference && preference.language !== selectedLanguage) {
                    dispatch(_action.PUT_DERIVED_DATA, { key: 'language', value: selectedLanguage });
                }

                let setAuthPayload = { isAuthenticated: !!user?.userUuid };
                if (preference) {
                    setAuthPayload.isUserVerified = hasUserVerificationStatus(preference, [
                        userVerificationStatuses.VERIFICATION_STATUS_COMPLETED,
                    ]);
                }
                if (payloadAuthType && preference) {
                    const firstLogIn = preference && Number(preference.logins_count) === 1;

                    setAuthPayload = { ...setAuthPayload, ...{ firstLogIn } };

                    if (payloadAuthType === authType.REGISTER) {
                        Vue.$gtm.query({
                            event: firstLogIn ? authType.REGISTER : authType.LOGIN,
                            userUuid: user?.userUuid,
                        });
                    } else if (payloadAuthType === authType.LOGIN) {
                        Vue.$gtm.query({
                            event: authType.LOGIN,
                            userUuid: user?.userUuid,
                            keepLoggedIn: !!keepLoggedIn,
                        });
                    }
                }
                commit(auth.mutation.SET_AUTH, setAuthPayload, { root: true });
                return data;
            })
            .catch((error) => {
                commit(platformMutation.SET_SETTINGS_ERROR, error);
                throw error;
            });
    }),
    [_action.PUT_DERIVED_DATA]: (context, keyValue) => {
        return Vue.$http.put(cms.putDerivedData, keyValue);
    },
    [_action.GET_DERIVED_DATA]: actionLoader(action.GET_DERIVED_DATA, ({ commit }) => {
        return Vue.$http
            .get(cms.getDerivedData)
            .then(({ data }) => data) // TODO: need to be deleted after the extra data wrapper is removed in the response
            .then(({ data }) => {
                commit(_mutation.UPDATE_PREFERENCE, data);
            })
            .catch((error) => {
                console.error({ error });
            });
    }),
    [_action.SET_NEW_BADGE_COUNT]: ({ state, commit }, payload) => {
        return Vue.$http
            .put(cms.putDerivedData, payload)
            .then(() => Vue.$http.get(`${cms.getDerivedData}/${payload.key}`))
            .then(({ data }) => {
                commit(_mutation.SET_NEW_BADGE_COUNT, data);
            });
    },
    [_action.TRACK_USER_PRESENCE]({ getters }) {
        const { userUuid: userId } = getters[_getter.GET_SETTINGS].user;
        if (userId) {
            Vue.$sentry.setUser({ id: userId });
        } else {
            Vue.$sentry.configureScope((scope) => scope.setUser(null));
        }
    },
    [_action.GET_TEXT]: actionLoader(action.GET_TEXT, ({ commit, dispatch, getters }, ids) => {
        const texts = getters.getText;
        const content = Object.keys(texts)
            .filter((item) => ids.includes(Number(item)))
            .map((item) => ({ [item]: texts[item] }));

        return dispatch(
            coreAction.LOADER,
            [
                content.length === ids.length ? content : null,
                (resolve, reject) =>
                    Vue.$http
                        .get(`${cms.getCmsDataText}/${ids.join()}`)
                        .then(({ data }) => {
                            Object.keys(data).forEach((value, index) =>
                                commit('setContentText', {
                                    data: data[value],
                                    id: ids[index],
                                })
                            );
                            resolve(data);
                        })
                        .catch((error) => reject(error)),
            ],
            { root: true }
        );
    }),
    [_action.GET_MODAL_WINDOW]: actionLoader(action.GET_MODAL_WINDOW, ({ state, commit, dispatch, getters }, payload) => {
        const { silent, id } = payload;
        if (state.content.error.message) {
            commit('removeContentError');
        }
        return dispatch(
            coreAction.LOADER,
            [
                getters.getText[id],
                (resolve, reject) =>
                    Vue.$http
                        .get(`${cms.getModalWindow}?Id=${id}`)
                        .then(({ data }) => {
                            commit('setContentText', { data, id });
                            resolve(data);
                        })
                        .catch((error) => {
                            if (!silent) {
                                commit('setContentError', {
                                    message: helper.processErrorResponse(error, 'ui.common.error.failedToLoadContent'),
                                    statusCode: error.statusCode,
                                });
                            }
                            reject(error);
                        }),
            ],
            { root: true }
        );
    }),
    [_action.GET_ANNOUNCEMENT]: actionLoader(action.GET_ANNOUNCEMENT, ({ commit }) => {
        return Vue.$http
            .get(cms.getAnnouncement)
            .then(({ data }) => {
                commit(_mutation.SET_ANNOUNCEMENT, data.result);
            })
            .catch((error) => console.error(`${action.GET_ANNOUNCEMENT} Response Error`, [error]));
    }),
    [_action.GET_CONTENT]: actionLoader(action.GET_CONTENT, ({ state, commit, dispatch, getters, rootGetters }, payload) => {
        if (state.content.error.message) {
            commit('removeContentError');
        }
        const { forceGetContent } = state.content;
        const slotIds = Array.isArray(payload) ? payload : [payload];
        const outdatedSlotKeys = forceGetContent
            ? slotIds
            : slotIds.filter(
                  (slotId) =>
                      !(
                          Object.keys(state.content.slots).includes(slotId) &&
                          new Date() - state.content.slots[slotId].timestamp < PAGE_CACHE_TIME
                      )
              );
        const slots = slotIds.filter((it) => !outdatedSlotKeys.includes(it)).map((slotId) => ({ [slotId]: state.content.slots[slotId] }));
        return dispatch(
            coreAction.LOADER,
            [
                slots.length === slotIds.length ? slots : null,
                (resolve, reject) => {
                    dispatch(_action.GET_CONTENT_V2, { paths: slotIds, errorCode: OLD_CMS_404_ERROR })
                        .then((content) => {
                            const country = getters[_getter.GET_COUNTRY];
                            const timestamp = new Date();
                            const isAuthenticated = rootGetters[auth.getter.IS_AUTHENTICATED];
                            const processedContent = outdatedSlotKeys.reduce(
                                (processed, slotId) => ({
                                    ...processed,
                                    ...{
                                        [slotId]: {
                                            isAuthenticated,
                                            timestamp,
                                            ...processStrapiContent(
                                                content.find((it) =>
                                                    it.links.some((link) => {
                                                        return link.link === slotId.replace(/_/g, '').toLowerCase();
                                                    })
                                                ),
                                                country
                                            ),
                                        },
                                    },
                                }),
                                {}
                            );
                            commit(_mutation.SET_FORCE_GET_CONTENT, false);
                            commit('setContentSlots', processedContent);
                            resolve(processedContent);
                        })
                        .catch(() => {
                            Vue.$http
                                .get(`${cms.getCmsComponentData}/${outdatedSlotKeys.join(',')}`)
                                .then(({ data }) => {
                                    const language = rootGetters['translations/getSelectedLanguage'];
                                    return processMultiLanguageResponse(data, language);
                                })
                                .then((content) => {
                                    const isAuthenticated = rootGetters[auth.getter.IS_AUTHENTICATED];
                                    const timestamp = new Date();
                                    const processedContent = outdatedSlotKeys.reduce(
                                        (processed, slotId) => ({
                                            ...processed,
                                            ...{
                                                [slotId]: {
                                                    isAuthenticated,
                                                    timestamp,
                                                    ...processContentData(content[slotId]),
                                                },
                                            },
                                        }),
                                        {}
                                    );
                                    commit(_mutation.SET_FORCE_GET_CONTENT, false);
                                    commit('setContentSlots', processedContent);
                                    resolve(processedContent);
                                })
                                .catch((error) => {
                                    if (SLOT_IDS_FOR_ERRORS.some((id) => slotIds.includes(id))) {
                                        commit('setContentError', {
                                            message: helper.processErrorResponse(error, 'ui.common.error.failedToLoadContent'),
                                            statusCode: error.statusCode,
                                        });
                                    }
                                    reject(error);
                                });
                        });
                },
            ],
            { root: true }
        );
    }),
    [_action.GET_CONTENT_V2]: ({ state, commit, dispatch, getters, rootGetters }, { paths, errorCode }) => {
        const locale = rootGetters['translations/getSelectedLanguage'];
        const query = qs.stringify(
            {
                populate: strapiPopulateFields.PAGES,
                filters: {
                    isSlot: true,
                    links: {
                        link: {
                            $in: paths.map((key) => key.replace(/_/g, '').toLowerCase()),
                        },
                    },
                    jurisdictions: {
                        hostname: {
                            $eq: getJurisdictionHostname(),
                        },
                    },
                },
                locale,
            },
            {
                encodeValuesOnly: true,
            }
        );

        return new Promise((resolve, reject) => {
            if (errorCode === OLD_CMS_404_ERROR) {
                Vue.$http
                    .get(`${strapi.getPages}?${query}`)
                    .then(({ data }) => {
                        if (data.data.length === paths.length) {
                            const filteredData = data.data.map((content) => [content].reduce((acc, { attributes }) => attributes, {}));
                            resolve(filteredData);
                        } else {
                            reject(strapiError404());
                        }
                    })
                    .catch((strapiError) => {
                        reject(strapiError);
                    });
            } else {
                reject(cmsError404());
            }
        });
    },
    [_action.REFRESH_CONTENT]: actionLoader(action.REFRESH_CONTENT, ({ state, commit, rootGetters }, payload) => {
        if (state.content.error.message) {
            commit('removeContentError');
        }
        const slotId = payload.slotId || payload;
        return Vue.$http
            .get(`${cms.getCmsComponentData}/${slotId}`)
            .then(({ data }) => {
                const language = rootGetters['translations/getSelectedLanguage'];
                return processMultiLanguageResponse(data, language);
            })
            .then((content) => {
                const isAuthenticated = rootGetters[auth.getter.IS_AUTHENTICATED];
                const data = {
                    isAuthenticated,
                    timestamp: new Date(),
                    ...processContentData(content[slotId]),
                };
                commit('setContentSlots', { [slotId]: data });
            })
            .catch((error) => {
                if (SLOT_IDS_FOR_ERRORS.includes(slotId)) {
                    commit('setContentError', {
                        message: helper.processErrorResponse(error, 'ui.common.error.failedToLoadContent'),
                        statusCode: error.statusCode,
                    });
                }
            });
    }),
    [_action.GET_PAGE]: actionLoader(action.GET_PAGE, ({ state, commit, dispatch, getters, rootGetters }, path) => {
        if (state.content.error.message) {
            commit('removeContentError');
        }
        const page = getters.getPages[path];
        const isAuthenticatedMatches = page && rootGetters[auth.getter.IS_AUTHENTICATED] === page.isAuthenticated;
        const isNotExpired = page && new Date() - page.timestamp < PAGE_CACHE_TIME;
        const isAuthenticated = rootGetters[auth.getter.IS_AUTHENTICATED];
        const language = rootGetters['translations/getSelectedLanguage'];
        return dispatch(
            coreAction.LOADER,
            [
                isAuthenticatedMatches && isNotExpired && page,
                (resolve, reject) =>
                    Vue.$http
                        .get(`${cms.getCmsPage}/${path}`)
                        .then((response) => {
                            return processMultiLanguageResponse({ [path]: response.data }, language);
                        })
                        .then((content) => {
                            const enableNewCasino = rootGetters[platformGetter.GET_BRAND_PREFERENCE].enableNewCasinoVTwo;
                            if (enableNewCasino && [routePath.CASINO, routePath.GAMES].includes(`/${path}`)) {
                                const error = new Error('skipping old casino');
                                error.errorCode = OLD_CMS_404_ERROR;
                                return Promise.reject(error);
                            }
                            const data = {
                                isAuthenticated,
                                ...processContentData(content[path]),
                            };
                            commit('setPage', { data, path });
                            resolve(data);
                        })
                        .catch((error) => {
                            const contentError = {
                                message: helper.processErrorResponse(error, 'ui.common.error.failedToLoadContent'),
                                statusCode: error.statusCode,
                                errorCode: error.errorCode,
                            };
                            dispatch(_action.GET_PAGE_V2, { path, errorCode: error.errorCode })
                                .then((content) => {
                                    const country = getters[_getter.GET_COUNTRY];
                                    const data = {
                                        isAuthenticated,
                                        ...processStrapiContent(content, country),
                                        PageTemplate: SimplePageTemplateName,
                                        strapi: true,
                                    };
                                    commit('setPage', { data, path });
                                    resolve(data);
                                })
                                .catch(() => {
                                    commit('setContentError', contentError);
                                    reject(error);
                                });
                        }),
            ],
            { root: true }
        );
    }),
    [_action.GET_PAGE_V2]: ({ state, commit, dispatch, getters, rootGetters }, { path, errorCode }) => {
        const locale = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
        const query = qs.stringify(
            {
                populate: strapiPopulateFields.PAGES,
                filters: {
                    $or: [{ isSlot: { $notNull: false } }, { isSlot: { $eq: false } }],
                    links: { link: { $eq: path } },
                    jurisdictions: { hostname: { $eq: getJurisdictionHostname() } },
                },
                locale,
                pagination: {
                    pageSize: 1,
                },
            },
            {
                encodeValuesOnly: true,
            }
        );

        return new Promise((resolve, reject) => {
            if (errorCode === OLD_CMS_404_ERROR) {
                Vue.$http
                    .get(`${strapi.getPages}?${query}`)
                    .then(({ data }) => {
                        if (data.data.length) {
                            resolve(flatDataAttributes(data.data[0]));
                        } else {
                            reject(strapiError404());
                        }
                    })
                    .catch((strapiError) => {
                        reject(strapiError);
                    });
            } else {
                reject(cmsError404());
            }
        });
    },
    [_action.GET_PAGE_PREVIEW]: actionLoader(action.GET_PAGE_PREVIEW, ({ commit, getters }, { previewId, path }) => {
        return new Promise((resolve, reject) => {
            Vue.$http
                .get(`${strapi.getPagePreview}/${previewId}/${Date.now()}`)
                .then(({ data }) => {
                    if (data) {
                        const content = {
                            ...processStrapiContent(data, getters[_getter.GET_COUNTRY]),
                            isAuthenticated: false,
                            strapi: true,
                            PageTemplate: SimplePageTemplateName,
                        };
                        commit('setPage', { data: content, path });
                        resolve(content);
                    } else {
                        reject(strapiError404());
                    }
                })
                .catch(reject);
        });
    }),
    [_action.GET_ALL_JURISDICTIONS]: ({ commit }) => {
        const query = qs.stringify({
            fields: ['countryCode'],
            populate: {
                brandPreferences: {
                    fields: ['countryName', 'brandName'],
                    populate: {
                        countryFlag: true,
                    },
                },
            },
        });

        return new Promise((resolve, reject) => {
            Vue.$http
                .get(`${strapi.getConfig}?${query}`)
                .then(({ data }) => {
                    commit(_mutation.SET_ALL_JURISDICTIONS, data.data);
                    resolve(data.data);
                })
                .catch(reject);
        });
    },
    [_action.GET_CONFIG]: ({ rootGetters }) => {
        const selectedLanguage = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
        const query = qs.stringify(
            {
                populate: { ...strapiPopulateFields.CONFIG },
                filters: { hostname: { $eq: getJurisdictionHostname() } },
                locale: selectedLanguage || 'all',
                pagination: { pageSize: selectedLanguage ? 1 : MAXIMUM_PAGE_SIZE },
            },
            {
                encodeValuesOnly: true,
            }
        );
        return Vue.$http
            .get(`${strapi.getConfig}?${query}`)
            .then(({ data }) => data.data)
            .catch((strapiError) => {
                Vue.$sentry.withScope((scope) => {
                    scope.setExtras({
                        error: strapiError,
                        selectedLanguage,
                    });
                    scope.setTag('statusCode', strapiError.statusCode);
                    scope.setTag('scope', 'strapi');
                    scope.setTag('config', 'unavailable');
                    scope.setLevel('fatal');
                    Vue.$sentry.captureMessage('FATAL_ERROR_STRAPI_CONFIG');
                });
                throw strapiError;
            });
    },
    [_action.SET_CONFIG]: ({ commit, getters, rootGetters, dispatch }, { strapi, componentData }) => {
        const defaultLanguage = getObjectField(componentData, 'brand.details.defaultLanguage', 'en');
        const clientLanguage = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE_OR_DEFAULT];
        const configWithUserLocale =
            strapi.length > 1
                ? strapi.find(({ attributes }) => attributes.locale === clientLanguage) ||
                  strapi.find(({ attributes }) => attributes.locale === defaultLanguage)
                : strapi[0];
        const flatData = processStrapiConfig(flatDataAttributes(configWithUserLocale));
        /**
         * Populate strapi brand preferences
         * @type {*&{countryFlag: (*|{[p: string]: *})}}
         */
        /**
         * Use global configuration if applied to brand.
         * @type {boolean}
         */
        const hasGlobalConfigData = !!getObjectField(flatData, 'globalConfig.data');
        const config = {
            ...flatData,
            brandPreferences: processStrapiBrandPreferences(flatData),
            globalConfig: processGlobalConfig(hasGlobalConfigData ? getStrapiData('globalConfig')(flatData) : { competitionIcons: [] }),
        };

        const { locale, localizations } = config;
        /**
         * Set app language based on:
         * 1. Language was set previously.
         * 2. Language is based on query or determined by browser AND matches available locales
         * 3. Language is based on default settings AND matches available locales
         */
        commit(translationsMutation.SET_LANGUAGE, locale, { root: true });
        /**
         * Set localized configuration
         */
        commit(_mutation.SET_CONFIG, config);
        /**
         * Set current country code
         */
        commit(_mutation.SET_COUNTRY_CODE_IS, config);
        /**
         * Pass localization data to get translation file and set user locale
         */
        const extraLocales = localizations.data.map(({ attributes }) => attributes.locale);
        /**
         * Save available locales
         */
        commit(translationsMutation.SET_CONFIGURATION, { locale, extraLocales }, { root: true });
        return { locale, extraLocales };
    },
    [_action.FETCH_REGIONS]: actionLoader(action.FETCH_REGIONS, ({ commit, state, getters }, { skip = 0, take = 120 } = {}) => {
        if (state.regions.length) {
            return new Promise((resolve) => resolve());
        }
        const { regionId } = getters[_getter.GET_BRAND_PREFERENCE];
        return Vue.$http
            .get(`${jurisdiction.getRegions}/${regionId}/subregions?skip=${skip}&take=${take}`)
            .then(({ data }) => commit(_mutation.SET_REGIONS, data.items))
            .catch((error) => console.error(`${action.FETCH_REGIONS} Response Error`, [error]));
    }),
    [_action.CALL_ME_REQUEST]: actionLoader(action.CALL_ME_REQUEST, ({ state, commit, getters }, { phoneNumber, categoryId }) => {
        const jurisdiction = getters[_getter.GET_COUNTRY].toLowerCase();
        const identifier = getters[_getter.GET_BRAND]?.identifier;

        const payload = {
            phoneNumber,
            ...(categoryId && { categoryId }),
            identifier,
        };

        let url;

        if (config.isProd()) {
            const host = location.host.replace('www.', '');
            url = `https://support.${host}/callme/request-call`;
        } else {
            url = `https://${jurisdiction}.support.verekuu.com/callme/request-call`;
        }

        // pawaDesk request currently works only in prod, make url dynamic when all environments are available
        return Vue.$http
            .post(url, payload, { withCredentials: false, timeout: 60000 })
            .then(({ data }) => {
                commit(_mutation.SET_CALL_ME_STATUS, { successMessage: Vue.$t('ui.callMeComponent.response.success') });
                return data?.success;
            })
            .catch((error) => {
                const message = error?.response?.data?.error
                    ? Vue.$t('ui.callMeComponent.response.error', [phoneNumber])
                    : Vue.$t('ui.callMeComponent.response.genericError');
                commit(_mutation.SET_CALL_ME_STATUS, {
                    errorMessage: helper.processErrorResponse({ message }),
                });
            });
    }),
    [_action.GET_CALL_ME_DATA]: actionLoader(action.GET_CALL_ME_DATA, ({ commit, getters, rootGetters }) => {
        const language = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];
        const jurisdiction = getters[_getter.GET_COUNTRY]?.toLowerCase();
        const brandIdentifier = getters[_getter.GET_BRAND]?.identifier;
        const host = location.host.replace('www.', '');
        const url = config.isProd() ? `https://support.${host}/callme/data` : `https://${jurisdiction}.support.verekuu.com/callme/data`;

        return Vue.$http.post(url, { brandIdentifier }, { withCredentials: false, timeout: 60000 }).then(({ data }) =>
            commit(_mutation.SET_CALL_ME_DATA, {
                ...data,
                callMeCategories: (data?.callMeCategories || [])
                    .filter((category) => category?.translations?.length)
                    .sort((a, b) => a?.priority - b?.priority)
                    .map((category) => {
                        const { categoryDropdownTitle, categoryDropdownExtraData } =
                            (category?.translations || []).find((translation) => translation?.languageCode === language) || {};
                        return {
                            id: category?.id,
                            priority: category?.priority,
                            reason: categoryDropdownTitle,
                            text: categoryDropdownExtraData,
                        };
                    }),
            })
        );
    }),
    [_action.SET_AFFILIATE_ID]({ commit }, { path, id }) {
        Vue.$http.get(path).catch((error) => console.error(`${action.SET_AFFILIATE_ID} Response Error`, [error, path]));
        commit(_mutation.SET_AFFILIATE_ID, id);
    },
    [_action.GET_STATEMENT]: actionLoader(action.GET_STATEMENT, ({ commit }, payload) => {
        const { take, nextPageId, currency } = payload;
        return Vue.$http
            .post(ledger.getStatement, {
                nextPageId,
                pageSize: take,
                currency,
            })
            .then(({ data }) => {
                const { nextPageId, transactions } = data;
                commit(_mutation.SET_STATEMENT, {
                    hasMoreResults: !!nextPageId,
                    nextPageId,
                    transactions: transactions.map((transaction) => {
                        const { balance, currency, change, created, relatedId, relatedType, metadata } = transaction;
                        const { relatedId: metaRelatedId } = metadata || {};
                        const transactionId = metaRelatedId || relatedId;
                        return {
                            ledger: transaction,
                            transactionId,
                            operationType: relatedType,
                            dateTime: created,
                            totalAmount: { amount: change, currency },
                            totalBalance: { amount: balance, currency },
                        };
                    }),
                });
            })
            .catch((error) => {
                commit('setStatementError', {
                    message: helper.processErrorResponse(error),
                    statusCode: error.statusCode,
                });
            });
    }),
    [_action.RESET_STATEMENT]({ commit }) {
        commit(_mutation.RESET_STATEMENT);
    },
    [_action.TRACK_USER_DATA]({ commit, state, getters, rootGetters }) {
        const {
            user,
            currency,
            brand: { brand },
        } = getters[_getter.GET_SETTINGS];
        const { countryName } = getters[_getter.GET_BRAND_PREFERENCE];
        const balance = rootGetters[auth.getter.GET_BALANCE];
        if (!user.userUuid !== (balance === null)) {
            return;
        }
        Vue.$gtm.query({
            user: {
                userUuid: user?.userUuid,
                balance,
                currency: currency.code,
            },
        });
        if (!state.gtmActive) {
            Vue.$gtm.query({
                site: {
                    release: process.env.VUE_APP_RELEASE_VERSION,
                    brand,
                    jurisdiction: countryName,
                    environment: config.isProd(),
                },
            });
            Vue.$gtm.query({
                event: 'gtm.js',
                'gtm.start': new Date().getTime(),
            });
            commit(_mutation.ACTIVATE_GTM);
        }
    },
    [_action.PAWAPASS_VERIFICATION]: actionLoader(action.PAWAPASS_VERIFICATION, ({ commit, dispatch, rootGetters }, payload = {}) => {
        const language = rootGetters[translationsGetter.GET_SELECTED_LANGUAGE];

        return new Promise((resolve) => {
            if (!payload?.reason) return resolve();
            dispatch(_action.PUT_DERIVED_DATA, {
                key: 'verification_reason',
                value: payload.reason,
            }).finally(() => resolve());
        }).then(() => {
            return Vue.$http
                .post(`${pawapass.verifications}?pref_lang=${language}`, {
                    ...(payload?.requirements && { requirements: payload?.requirements }),
                    redirectUrl: payload?.redirectUrl || location.href,
                })
                .then((res) => res.data?.url);
        });
    }),
    [_action.GET_CHIPS_CASH_IN_TAX_PREVIEW]: actionLoader(action.GET_CHIPS_CASH_IN_TAX_PREVIEW, ({ commit }) => {
        commit(_mutation.RESET_CHIPS_ERROR);
        return Vue.$http
            .get(casino.getCashInTaxPreview)
            .then(({ data }) => {
                commit(_mutation.SET_CHIPS_CASH_IN_TAX_DETAILS, data);
                return data;
            })
            .catch((error) => {
                const errorMessage = helper.processErrorResponse(error);
                commit(_mutation.SET_CHIPS_ERROR, { errorMessage });
                throw errorMessage;
            });
    }),
    [_action.GET_CHIPS_TOP_UP_TAX_PREVIEW]: actionLoader(action.GET_CHIPS_TOP_UP_TAX_PREVIEW, ({ commit }, amount) => {
        commit(_mutation.RESET_CHIPS_ERROR);
        return Vue.$http
            .post(casino.getChipsTopUpTaxPreview, { amount })
            .then(({ data }) => {
                commit(_mutation.SET_CHIPS_TOP_UP_TAX_DETAILS, data);
                return data;
            })
            .catch((error) => {
                const errorMessage = helper.processErrorResponse(error);
                commit(_mutation.SET_CHIPS_ERROR, { errorMessage });
                throw errorMessage;
            });
    }),
    [_action.CHIPS_BALANCE_EXCHANGE]: actionLoader(action.CHIPS_BALANCE_EXCHANGE, ({ commit, getters, dispatch }, payload) => {
        commit(_mutation.RESET_CHIPS_ERROR);
        const preference = getters[_getter.GET_PREFERENCE] || {};
        const { isTopUp, amount, notificationData } = payload || {};
        const requestData = isTopUp && { amount };
        const event = isTopUp ? 'chips_topup_success' : 'chips_cashin_success';
        return Vue.$http
            .post(isTopUp ? casino.chipsTopUp : casino.chipsCashIn, requestData)
            .then(({ data }) => {
                const { currency = {}, chips = {} } = getters[_getter.GET_SETTINGS];
                const accountBalance = helper.findCurrencyDetails(data.result, currency.platformCode);
                const chipsBalance = helper.findCurrencyDetails(data.result, chips.currency.platformCode);
                commit(
                    auth.mutation.SET_AUTH,
                    {
                        accountBalance: accountBalance.balance,
                        chipsBalance: chipsBalance.balance,
                    },
                    { root: true }
                );
                if (isTopUp) {
                    if (!preference.topped_up_chips) {
                        dispatch(_action.PUT_DERIVED_DATA, {
                            key: 'topped_up_chips',
                            value: true,
                        })
                            .then(() => {
                                commit(_mutation.UPDATE_PREFERENCE, {
                                    topped_up_chips: true,
                                });
                            })
                            .finally(() => {
                                if (notificationData) {
                                    dispatch(storeAction.NOTIFY, notificationData, { root: true });
                                }
                            });
                    } else if (notificationData) {
                        dispatch(storeAction.NOTIFY, notificationData, { root: true });
                    }
                }

                Vue.$gtm.query({
                    event,
                    amount,
                });
                return data;
            })
            .catch((error) => {
                const errorMessage = helper.processErrorResponse(error);
                commit(_mutation.SET_CHIPS_ERROR, {
                    errorMessage,
                });
                Vue.$gtm.query({
                    event: isTopUp ? 'chips_topup_error' : 'chips_cashin_error',
                    amount,
                });
                throw errorMessage;
            });
    }),
    [_action.GET_CAMPAIGN_ELIGIBILITY]: actionLoader(action.GET_CAMPAIGN_ELIGIBILITY, ({ commit, getters, dispatch }) => {
        const campaignEligibility = getters[_getter.GET_CAMPAIGN_ELIGIBILITY];
        return dispatch(
            coreAction.LOADER,
            [
                campaignEligibility?.isEligible ? campaignEligibility : {},
                (resolve, reject) =>
                    Vue.$http
                        .get(campaign.eligibility)
                        .then(({ data }) => {
                            commit(_mutation.SET_CAMPAIGN_ELIGIBILITY, data);
                            resolve(data);
                        })
                        .catch((error) => reject(error)),
            ],
            { root: true }
        );
    }),
    [_action.SET_USING_CAMPAIGN_ELIGIBILITY]({ commit }, value) {
        commit(_mutation.SET_USING_CAMPAIGN_ELIGIBILITY, value);
    },
    [_action.UPDATE_LANGUAGE_PREFERENCE]: actionLoader(action.UPDATE_LANGUAGE_PREFERENCE, ({ dispatch }, language) => {
        return dispatch(_action.PUT_DERIVED_DATA, { key: 'language', value: language });
    }),
    [_action.CHANGE_SITE_LANGUAGE]({ rootGetters, dispatch }, { language, component }) {
        const isAuthenticated = rootGetters[auth.getter.IS_AUTHENTICATED];

        if (isAuthenticated) {
            dispatch(_action.UPDATE_LANGUAGE_PREFERENCE, language).then(() => {
                Vue.$gtm.query({ event: 'change_language', language, component });
                dispatch(translationsAction.SWITCH_LANGUAGE, language, { root: true });
            });
        } else {
            Vue.$gtm.query({ event: 'change_language', language, component });
            dispatch(translationsAction.SWITCH_LANGUAGE, language, { root: true });
        }
    },
    [_action.GET_ACTIVE_PROGRESSIVE_JACKPOTS]: actionLoader(action.GET_ACTIVE_PROGRESSIVE_JACKPOTS, ({ commit }) => {
        return Vue.$http.get(progressiveJackpots.active).then(({ data }) => {
            const { jackpots } = data;
            commit(_mutation.SET_ACTIVE_PROGRESSIVE_JACKPOTS, [...jackpots]);
            return jackpots;
        });
    }),
    [_action.POLL_ACTIVE_PROGRESSIVE_JACKPOTS]({ dispatch, state, commit }) {
        const { pollingId, lastRequestTimestamp } = state.progressiveJackpots;
        if (pollingId) return;

        if (new Date().getTime() > lastRequestTimestamp + DEFAULT_PROGRESSIVE_JP_POLLING_TIME) {
            dispatch(_action.GET_ACTIVE_PROGRESSIVE_JACKPOTS);
        }
        if (!deviceType.isPresto()) {
            const intervalId = setInterval(() => {
                dispatch(_action.GET_ACTIVE_PROGRESSIVE_JACKPOTS, true).then((jackpots) => {
                    if (!jackpots.length) {
                        commit(_mutation.CLEAR_ACTIVE_PROGRESSIVE_JACKPOT_POLLING);
                    }
                });
            }, DEFAULT_PROGRESSIVE_JP_POLLING_TIME);
            commit(_mutation.SET_ACTIVE_PROGRESSIVE_JACKPOT_POLLING, intervalId);
        }
    },
    [_action.GET_PROGRESSIVE_JACKPOT_WINNERS]: actionLoader(action.GET_PROGRESSIVE_JACKPOT_WINNERS, ({ commit }, payload) => {
        return Vue.$http.post(progressiveJackpots.winners, payload).then(({ data }) => {
            commit(_mutation.SET_PROGRESSIVE_JACKPOT_WINNERS, data.result);
        });
    }),
};

function processMultiLanguageResponse(data, language) {
    return Object.keys(data).reduce((acc, id) => {
        const slot = data[id];
        const content = slot.languages ? slot.languages[language] : slot?.content || {};
        return { ...acc, ...{ [id]: { ...slot, ...(Object.keys(content).length && { content }) } } };
    }, {});
}

function formatKeys(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, ...{ [key.charAt(0).toLowerCase() + key.slice(1)]: value } }), {});
}

function buildBreadcrumbs(pages) {
    let reversedPagesArr = [...pages].reverse();
    let breadcrumbs = [];

    pages.forEach((item) => {
        const url = reversedPagesArr.map((page) => page.link).join('/');
        reversedPagesArr.pop();
        breadcrumbs.push({ name: item.label || item.link, url });
    });

    return breadcrumbs;
}

function fillWithParentPagesLink(linksRef, page) {
    const link = getActualLinkFrom(page);

    if (link) {
        linksRef.push(link);
    }

    const parent = getParentPage(page);

    if (parent) {
        fillWithParentPagesLink(linksRef, parent);
    }
}

function createBreadcrumbs(page) {
    const parentsLinks = [];

    fillWithParentPagesLink(parentsLinks, page);

    return buildBreadcrumbs(parentsLinks).reverse();
}

function processStrapiConfig(data) {
    for (const type of Object.values(strapiMenus)) {
        const menu = getObjectField(data, type, undefined);
        if (menu) {
            data[type] = processStrapiMenu(menu);
        }
    }
    return data;
}

function getPathAndPublishUpdater(path) {
    return (entity) => {
        return {
            ...entity,
            publishedAt: Date.parse(entity.publishedAt),
            path: `${path}/${getActualHref(entity)}`,
        };
    };
}

function processStrapiBrandPreferences({ brandPreferences }) {
    return { ...brandPreferences, countryFlag: flatDataAttributes(brandPreferences.countryFlag.data) };
}

function processStrapiContent(data, country) {
    const getTags = getStrapiData('tags', []);
    const getChildPages = getStrapiData('childPages', []);
    const getPages = getStrapiData('pages', []);
    const getJurisdictions = getStrapiData('jurisdictions', []);

    try {
        const page = formatKeys(data);
        const { link: actualLink, label: actualLabel } = getActualLinkFrom(page);
        const breadcrumbs = createBreadcrumbs(getParentPage(page));
        const path = breadcrumbs.length ? `/${breadcrumbs[breadcrumbs.length - 1].url}/${actualLink}` : `/${actualLink}`;
        const pathAndPublishUpdater = getPathAndPublishUpdater(path);

        const tagsWithoutWrapper = getTags(page);
        const sportParams = page.content && getSportParams(page.content);
        const childPages = getChildPages(page).map(pathAndPublishUpdater);
        const tags = tagsWithoutWrapper.length > 0 ? getPages(tagsWithoutWrapper[0]).map(pathAndPublishUpdater) : [];
        const ids = new Set(childPages.map((page) => page.id));
        const mixedPages = [
            ...childPages,
            ...tags.filter((tagsPages) => !ids.has(tagsPages.id) && getActualHref(tagsPages) !== actualLink),
        ];

        const mergedPages = mixedPages
            .map((page) => ({
                ...page,
                jurisdictions: getJurisdictions(page).map(getAttribute('countryCode')),
            }))
            .filter((page) => page.jurisdictions.includes(country));

        return {
            ...page,
            ...(sportParams && { sportParams }),
            childPages,
            tags,
            breadcrumbs,
            path,
            link: actualLink,
            label: actualLabel,
            mergedPages,
            content: page.content.map((component) => {
                const content = formatKeys(component);
                const { __component, visibility } = content;
                const type = __component
                    .slice(__component.lastIndexOf('.') + 1)
                    .replace(/[^a-zA-Z ]/g, ' ')
                    .split(' ')
                    .map((name) => helper.capitalize(name))
                    .join('');
                return { content, type, visibility };
            }),
        };
    } catch (err) {
        console.error(`[Strapi] Couldn't not process Content`, err);
    }
}

function processStrapiMenu(menu) {
    const isHomePage = (path) => ['homepagecomponent', '/'].includes(path);

    return {
        ...menu,
        links: menu.links.map((link) => {
            const linksDataList = getObjectField(link, 'page.data.attributes.links', []);
            const activeLink = linksDataList.find((link) => link.isActual);
            const url = getObjectField(activeLink, 'link', '');
            let query = {};
            if (typeof link.query === 'string') {
                new URLSearchParams(link.query).forEach((val, key) => {
                    query[key] = val;
                });
            }
            const path = isHomePage(url) ? '/' : '/' + (url || '');
            const links = linksDataList.map((it) => (isHomePage(it.link) ? '/' : it.link));
            return {
                ...link,
                fullPath: path + (link.query || ''),
                path,
                links,
                query,
            };
        }),
    };
}

function processContentData(data) {
    // move to platform utils BP-16141
    const content =
        data.content &&
        data.content.map((item) => {
            if (!item.type && item.content) {
                item.type = 'NESTED_COMPONENTS';
            }
            return item;
        });

    const sportParams = content && getSportParams(content);

    if (!data.TOC) {
        return { ...data, content, ...(sportParams && { sportParams }) };
    }
    const tocContent = content.reduce((list, item, index) => {
        if (item.id && item.text) {
            const itemParentId = item.id.slice(0, item.id.lastIndexOf('-'));
            if (!list.find((listItem) => listItem.id.slice(0, item.id.lastIndexOf('-')) === itemParentId)) {
                list.push({
                    start: index,
                    ...item,
                });
            } else {
                const lastElement = list[list.length - 1];
                if (!lastElement.subContent) {
                    lastElement.subContent = [];
                }
                lastElement.subContent.push(item);
            }
        }
        return list;
    }, []);

    return { ...data, content, tocContent };
}

function getSportParams(content) {
    // move to sport utils BP-16141
    const events = content.filter(({ type, __component }) => type === 'EVENTS' || __component === 'widget.events');

    if (!events.length) {
        return null;
    }

    const LivePopular = Number(events.find((event) => event.live && event.popular)?.itemsToShow || 0);
    const eventParams = {
        queries: events
            .map(({ live, boosted, categoryIds, categoryId, itemsToShow }) =>
                buildEventsQueryParams({
                    eventType: live ? EventType.LIVE : EventType.UPCOMING,
                    categories: categoryIds || (categoryId ?? '').split(','),
                    take: itemsToShow ?? DEFAULT_TAKE,
                    onlyBoosted: !live ? boosted : undefined,
                })
            )
            .filter(({ query }) => query.categories),
    };

    return {
        eventParams,
        LivePopular,
    };
}

function calculateMinStakeWithTax(taxRate, bettingParams) {
    if (!taxRate) {
        return bettingParams.minStake;
    }

    const precision = Math.pow(10, bettingParams.precision || 2);

    return Math.round(bettingParams.minStake * (1 + taxRate) * precision) / precision;
}

function processGlobalConfig({ competitionIcons }) {
    const iconMap = {};
    const getUrl = getAttribute('url', '');

    competitionIcons
        .map(({ competitionIds, icon }) => {
            return {
                competitionIds: (competitionIds || '').match(/\d+/g) || [],
                icon: getUrl(icon),
            };
        })
        .forEach(({ competitionIds, icon }) => {
            competitionIds.forEach((id) => {
                iconMap[id] = icon;
            });
        });

    return { competitionIcons: iconMap };
}

function collectProgressiveJPByType(jackpots, key = 'jackpotType') {
    const collection = progressiveJpTypes.reduce((result, type) => {
        result[type] = [];
        return result;
    }, {});
    for (const jackpot of jackpots) {
        const jackpotType = jackpot[key].toLowerCase();
        if (collection[jackpotType]) {
            collection[jackpotType].push({ ...jackpot, jackpotType });
        }
    }
    return collection;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        auth: authModule,
        messaging: messagingModule,
    },
};
