<template>
    <ExpansionPanel
        v-if="!!tournaments && tournaments.length"
        data-test-id="menu-tournaments"
        :title="panelTitle"
        :control="!isPresto"
        :expanded="true"
    >
        <slot :current-route="currentRoute" :track-main-nav="trackMainNav">
            <MenuItem
                v-for="(tournament, index) in tournaments"
                :key="`tournament-${tournament.id}-${index}`"
                class="sub-menu"
                :selected="currentRoute.id === tournament.id"
                :link="{ name: routeGroupName, params: { id: tournament.id } }"
                :item="{
                    text: tournament.name,
                    control: {
                        text: tournament.eventCount,
                        iconDisabled: true,
                    },
                }"
                :data-test-id="`tournament-${tournament.id}`"
                @click.native="trackMainNav(tournament.name, 'tournaments_nav')"
            >
                <template slot="icon">
                    <ImageIcon
                        v-if="tournament.icon.src || tournament.icon.altSrc"
                        class="icon-status"
                        :src="tournament.icon.src"
                        :alt-src="tournament.icon.altSrc"
                    />
                </template>
            </MenuItem>
        </slot>
    </ExpansionPanel>
</template>

<script>
import { mapState } from 'vuex';
import { deviceType } from '@agi.packages/core';
import { MenuItem, ExpansionPanel } from '@agi.packages/core/components';
import { routeName } from '@/router/const-name';

export default {
    name: 'TournamentsList',
    components: { MenuItem, ExpansionPanel },
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            routeGroupName: routeName.GROUP,
            currentRoute: {
                name: '',
                id: null,
                category: null,
            },
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapState({
            tournaments: 'currentTournaments',
        }),
        panelTitle() {
            return this.title || this.$t('ui.eventFilters.popularTournaments');
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.$data.currentRoute = { name: to.name, id: to.params.id, category: to.query.category };
            },
        },
    },
    methods: {
        trackMainNav(element, event = 'main_nav') {
            this.$emit('onMenuItemClick', { element, event });
        },
    },
};
</script>

<style></style>
