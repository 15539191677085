<template>
    <div class="tournament-item">
        <TournamentsList class="light-panel-header">
            <FilterCheckboxItem
                checkbox-id="all-tournaments"
                :checked="isAllCompetitionsChecked({ competitions: filteredTournamentsByEventCount })"
                :label="$t('menus.all')"
                :counter="tournamentsTotalCount"
                @onCheck="onAllCompetitionsCheck({ checked: $event, competitions: filteredTournamentsByEventCount })"
            />
            <FilterCheckboxItem
                v-for="(tournament, index) in filteredTournamentsByEventCount"
                :key="`tournament-${tournament.id}-${index}`"
                :checkbox-id="tournament.id"
                :checked="isCompetitionChecked({ competition: tournament })"
                :label="tournament.name"
                :counter="getEventsCount(tournament)"
                @onCheck="onCompetitionCheck({ checked: $event, competition: tournament })"
            >
                <template slot="icon">
                    <ImageIcon
                        v-if="tournament.icon.src || tournament.icon.altSrc"
                        class="tournament-icon icon-size-small"
                        :src="tournament.icon.src"
                        :alt-src="tournament.icon.altSrc"
                    />
                </template>
            </FilterCheckboxItem>
        </TournamentsList>
        <CountryMenus :event-count-key="eventCountKey" class="light-panel-header">
            <template #default="{ menu }">
                <MenuItem
                    v-for="(country, index) in getFilteredCompetitionsByEventCount(menu.data)"
                    :key="`${menu.slug}-country-${country.region.id}-${index}`"
                    class="sub-menu"
                    :selected="isExpandedCountry(country.region.id)"
                    :item="{
                        text: country.region.name,
                        control: {
                            text: getEventsCount(country),
                            icon: isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                        },
                    }"
                    @onItemClick="toggleCountry(country.region.id)"
                >
                    <template slot="icon">
                        <ImageIcon
                            v-if="country.icon.src || country.icon.altSrc"
                            class="icon-status"
                            :src="country.icon.src"
                            :alt-src="country.icon.altSrc"
                        />
                    </template>
                    <template slot="content">
                        <ExpansionPanel v-if="country.competitions" :expanded="isExpandedCountry(country.region.id)">
                            <FilterCheckboxItem
                                :checkbox-id="`all-${country.region.name}-${country.region.id}`"
                                :checked="isAllCompetitionsChecked({ competitions: country.competitions })"
                                :label="$t('menus.all')"
                                :counter="getEventsCount(country)"
                                @onCheck="onAllCompetitionsCheck({ checked: $event, competitions: country.competitions })"
                            />
                            <FilterCheckboxItem
                                v-for="(competition, competitionIndex) in getFilteredCompetitionsByEventCount(country.competitions)"
                                :key="`${menu.slug}-country-filter-${competition.slug}-${competitionIndex}`"
                                :checkbox-id="`competition-${competitionIndex}-${country.region.id}`"
                                :checked="isCompetitionChecked({ competition })"
                                :label="competition.name"
                                :counter="getEventsCount(competition)"
                                @onCheck="onCompetitionCheck({ checked: $event, competition })"
                            />
                        </ExpansionPanel>
                    </template>
                </MenuItem>
            </template>
        </CountryMenus>
    </div>
</template>

<script>
import { FilterCheckboxItem, TournamentsList, CountryMenus } from '@agi.packages/sport/components';
import { MenuItem, ExpansionPanel } from '@agi.packages/core/components';
import { EventCategoryCountKeys } from '@/modules/sport/const/events-const';
import { routeName } from '@/router/const-name';

export default {
    name: 'FilterByTournament',
    components: { TournamentsList, FilterCheckboxItem, CountryMenus, MenuItem, ExpansionPanel },
    props: {
        tournaments: {
            type: Array,
            required: true,
        },
        competitions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            expandedCountries: [],
        };
    },
    computed: {
        tournamentsTotalCount() {
            return this.filteredTournamentsByEventCount.reduce(
                (accumulator, current) => accumulator + (this.getEventsCount(current) || 0),
                0
            );
        },
        filteredTournamentsByEventCount() {
            return this.tournaments.filter((tournament) => this.getEventsCount(tournament) > 0);
        },
        eventCountKey() {
            switch (this.$route.name) {
                case routeName.POPULAR:
                    return EventCategoryCountKeys.POPULAR;
                default:
                    return EventCategoryCountKeys.DEFAULT;
            }
        },
    },
    methods: {
        isAllCompetitionsChecked({ competitions }) {
            return !!competitions?.every((i) => this.competitions.includes(i.id));
        },
        onAllCompetitionsCheck({ checked, competitions }) {
            this.$emit('onAllCheck', { checked, competitions });
        },
        isCompetitionChecked({ competition }) {
            const { id } = competition;
            return this.competitions.includes(id);
        },
        onCompetitionCheck({ checked, competition }) {
            this.$emit('onCheck', { checked, competition });
        },
        isExpandedCountry(countryRegionId) {
            return this.expandedCountries.includes(countryRegionId);
        },
        toggleCountry(countryRegionId) {
            if (this.isExpandedCountry(countryRegionId)) {
                this.expandedCountries = this.expandedCountries.filter((i) => i !== countryRegionId);
            } else {
                this.expandedCountries.push(countryRegionId);
            }
        },
        getEventsCount(event) {
            return event[this.eventCountKey];
        },
        getFilteredCompetitionsByEventCount(competitions) {
            return competitions.filter((competition) => this.getEventsCount(competition) > 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.tournament-item {
    .light-panel-header:first-child {
        &::v-deep {
            .title {
                border-top: none;
            }
        }
    }

    .tournament-icon {
        margin-right: 4px;
    }
}

.light-panel-header {
    &::v-deep {
        &.expansion-panel,
        & > .expansion-panel {
            .title {
                background: $white-bg;
                padding: 12px;

                h3 {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

.sub-menu {
    &::v-deep {
        &.menu-item.selected > .link {
            font-weight: 500;
            color: inherit;

            svg.svg-icon {
                fill: $main-text;
            }
        }
    }
}
</style>
