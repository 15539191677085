<template>
    <div class="radio-item">
        <Radiobutton :id="radioId" :checked="checked" />
        <span class="radio-item-label">{{ label }}</span>
    </div>
</template>

<script>
import Radiobutton from '@/components/Radiobutton.vue';

export default {
    name: 'FilterRadioItem',
    components: { Radiobutton },
    props: {
        radioId: {
            type: String,
            default: 'radio',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'radio',
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-item {
    display: flex;
    padding: 11px 12px;
    border-top: 1px solid $light-grey;
    cursor: pointer;

    ::v-deep {
        .radio-button-label {
            vertical-align: top;
        }
    }

    &-label {
        @extend %body-normal-font-400;
    }
}
</style>
