const fixedOddsBetsWsAdmin = '/api/fixed-odds-bets/admin/v1';
const fixedOddsBetslip = '/api/fixed-odds-bets/v1/betslip';
const fixedOddsPlaceBet = '/api/fixed-odds-bets/v1/place-bet';
const cashout = '/api/cashout/v2';
const autoCashout = '/api/auto-cashout/v1';
const sportsbookV1 = '/api/sportsbook/v1';
const sportsbookV2 = '/api/sportsbook/v2';
const sportsbookVirtualV1 = '/api/sportsbook/virtual/v1';
const sportsbookVirtualV2 = '/api/sportsbook/virtual/v2';
const poolV1 = '/api/pool/v1';

export const pool = {
    getJackpotTickets: `${poolV1}/ticket`,
    addJackpotTicket: `${poolV1}/place-bet`,
    getJackpots: `${poolV1}/pool`,
};

export const pricing = {
    getVirtualRounds: `/api/virtuals/front-page`,
    getVirtualLiveRound: `/api/virtuals/in-play`,
    getVirtualStandings: `/api/virtuals/standing`,
    getVirtualMatchUps: `/api/virtuals/standing/matchUps`,

    getSearchResult: `${sportsbookV1}/search`,

    getPrices: `${sportsbookV2}/prices/list`,
    getEventsPricesByIds: `${sportsbookV2}/prices/full/list`,

    getVirtualFullPrices: `${sportsbookVirtualV2}/prices/full/list`,
    getVirtualEventsByRoundId: `${sportsbookVirtualV1}/events/list/by-round`,

    getCategoryActiveRegions: `${sportsbookV2}/categories/list`,
};

export const sportsbook = {
    authorizeStatsPerform: `${sportsbookV1}/video-stream/perform/authorize`,
    authorizeSportRadar: `${sportsbookV1}/video-stream/sportradar/authorize`,
    getEventWithPricesById: `${sportsbookV1}/events`,
    getEventsByIds: `${sportsbookV1}/events/list/by-ids`,
    getEventsByQueries: `${sportsbookV2}/events/lists/by-queries`,
};

export const betslip = {
    getVirtualBetslips: `${fixedOddsBetslip}/virtual`,
    getSettledBetslips: `${fixedOddsBetslip}/settled`,
    getPendingBetslips: `${fixedOddsBetslip}/pending`,
    getSingleBetslip: `${fixedOddsBetslip}`,
    getBetslipStatus: `${fixedOddsPlaceBet}/check-status`,
    getInPlayList: `${sportsbookV1}/scoreboards/list/all`,
    placeBet: `${fixedOddsPlaceBet}/real`,
    placeVirtualBet: `${fixedOddsPlaceBet}/virtual`,
    getDetailBetslip: `${fixedOddsBetsWsAdmin}/betslip/detail-betslip`,
    createBookingCode: `${sportsbookV1}/booking-number`,
    getBookingSelection: `${sportsbookV1}/booking-number`,
    requestCashoutOffer: `${cashout}/request-offer`,
    autoCashoutOffer: `${autoCashout}/betslip`,
    autoCashoutOfferList: `${autoCashout}/betslip/list`,
    requestCashoutOfferAuto: `${cashout}/request-offer-auto`,
    confirmCashoutOffer: `${cashout}/confirm`,
    statusCashoutOffer: `${cashout}/status`,
};
export default {
    pricing,
    pool,
    betslip,
};
