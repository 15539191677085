<template>
    <div v-if="isBetslipMenu" class="circle-menu">
        <div class="ellipse-left" />
        <div class="ellipse-right" />
        <div class="betslip-menu" :class="{ 'betslip-highlight': betCount }">
            <a :class="{ 'router-link-active': activeKey }" @click="menuAction(menuItem)">
                <SvgIcon v-if="!$mq.isVerySmall || ($mq.isVerySmall && !betCount)" :icon-id="betslipIcon" class="menu-icon" />
                <span v-if="betCount" class="betslip-count" :class="{ 'no-margin': $mq.isVerySmall && betCount }">{{ betCount }}</span>
                <div v-if="!$mq.isVerySmall && !betCount" class="menu-title">{{ $t(`ui.common.${menuItem.name}`) }}</div>
            </a>
        </div>
    </div>
    <div v-else class="menu-item">
        <router-link v-if="menuItem.url" :to="menuItem.url" class="action-link" @click.native="trackNavigation(menuItem.name)">
            <SvgIcon
                :icon-id="menuItem.name === 'sports' ? $t(`project.sports.${currentCategoryId}.icon`) : menuItem.icon"
                class="menu-icon"
            />
            <span v-if="menuItem.counter > 0" class="menu-item-counter" :class="{ more: menuItem.counter > MENU_ITEM_COUNT_MAX_NUMBER }">
                {{ formatNavigationItemCount(menuItem.counter) }}
            </span>

            <div v-if="!$mq.isVerySmall" class="menu-title">{{ $t(`ui.common.${menuItem.name}`) }}</div>
            <SvgIcon v-if="showBadge" class="icon-messages-menu" icon-id="icon-messages-menu" />
        </router-link>
        <a
            v-else
            v-click-outside:[true]="closeMenu"
            class="action-link"
            :class="{ 'router-link-active': activeKey }"
            @click="menuAction(menuItem)"
        >
            <SvgIcon :icon-id="menuItem.icon" class="menu-icon" />
            <div v-if="!$mq.isVerySmall" class="menu-title">{{ $t(`ui.common.${menuItem.name}`) }}</div>
            <SvgIcon v-if="showBadge" class="icon-messages-menu" icon-id="icon-messages-menu" />
        </a>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { action, getter as generalGetter } from '@/store/store';
import { getter as platformGetter, auth } from '@agi.packages/platform';
import { betslip } from '@agi.packages/sport';
import { MENU_ITEM_COUNT_MAX_NUMBER } from '@/const/navigation';
import { formatNavigationItemCount } from '@/utils/formatNavigationItemCount';

export default {
    name: 'NavigationItem',
    props: {
        menuItem: {
            type: Object,
        },
    },
    data() {
        return {
            activeMenu: false,
        };
    },
    computed: {
        ...mapState({
            sidebarOpen: (state) => state.ui.sidebarOpen,
            betslipOpen: (state) => state.ui.betslipOpen,
            showBottomMenu: (state) => state.ui.bottomMenuOpen,
            newMessagesNumber: (state) => state.platform.messaging.notSeen,
        }),
        ...mapGetters({
            selectedBetslip: betslip.getter.GET_SELECTED_BETSLIP,
            betslipType: betslip.getter.GET_BETSLIP_TYPE,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        activeKey() {
            return this[this.menuItem.activeKey];
        },
        betCount() {
            const { selectedBetsIds } = this.selectedBetslip;
            return selectedBetsIds.length;
        },
        isBetslipMenu() {
            return this.menuItem.name === 'betslip';
        },
        showBadge() {
            return !this.showBottomMenu && this.isAuthenticated && this.newMessagesNumber && this.menuItem.name === 'account';
        },
        betslipIcon() {
            return this.betslipType === 'regular' ? 'icon-betslip' : 'icon-virtual-sport';
        },
        MENU_ITEM_COUNT_MAX_NUMBER() {
            return MENU_ITEM_COUNT_MAX_NUMBER;
        },
    },
    methods: {
        formatNavigationItemCount,
        menuAction(menuItem) {
            this.trackNavigation(menuItem.name);
            switch (menuItem.action) {
                case 'openSidebar': {
                    return this.toggleSidebar();
                }
                case 'openBetslip': {
                    return this.toggleBetslip();
                }
                case 'openBottomMenu': {
                    return this.toggleMenu();
                }
            }
        },
        toggleSidebar() {
            this.$store.commit('closeMobileSearch');
            this.betslipOpen && this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            this.$store.dispatch(action.TOGGLE_SIDEBAR);
        },
        toggleBetslip() {
            this.$store.commit('closeMobileSearch');
            this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            this.$store.dispatch(action.SET_SIDEBAR_STATE, false);
        },
        toggleMenu() {
            this.$store.commit('closeMobileSearch');
            this.betslipOpen && this.$store.dispatch(action.TOGGLE_BETSLIP_STATE);
            this.$store.dispatch(action.SET_SIDEBAR_STATE, false);
            this.$store.commit('setBottomMenuState', !this.showBottomMenu);
        },
        closeMenu() {
            if (this.menuItem.name !== 'menu') return;
            if (this.showBottomMenu) {
                this.$store.commit('setBottomMenuState', false);
            }
        },
        trackNavigation(menuName) {
            this.$gtm.query({
                event: 'bottom_navigation_click',
                click_text: menuName,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.menu-icon {
    width: 20px;
    height: 20px;
    fill: $navigation-menu-color;
}

.menu-title {
    color: $navigation-menu-color;
    margin-top: 7px;
}

.menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.action-link {
    position: relative;
}

.circle-menu {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
}

.betslip-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    border: 4px solid $brand-secondary-lighten-1;
    border-radius: 50%;
    left: 50%;
    bottom: 4px;
    transform: translateX(-50%);
    background-color: $betpawa-black;
    overflow: hidden;

    @include oldschool {
        width: 56px;
        height: 56px;
        bottom: 0;
    }
}

.betslip-highlight {
    border-color: $primary-color;
    box-shadow: 0 0 6px $betpawa-black;
}

.betslip-count {
    @extend %body-normal-font-400;
    margin-left: 6px;
    color: $navigation-menu-color;
}

.ellipse-left,
.ellipse-right {
    background-color: $betpawa-black;
    height: 100%;
    width: 50%;
}

.ellipse-left {
    border-top-right-radius: 100%;
}

.ellipse-right {
    border-top-left-radius: 100%;
}

a {
    &.router-link-active {
        color: $header-active-item-color;

        svg.menu-icon {
            fill: $header-active-item-color;
        }

        .menu-title,
        .betslip-count {
            color: $header-active-item-color;
        }
    }
}

.no-margin {
    margin: 0;
}

.icon-messages-menu {
    position: absolute;
    top: -5px;
    right: 0;
    width: 15px;
    height: 12px;
    fill: $white-bg;
    padding: 1px;
    background: $badge-new-messages-background;

    @include oldschool {
        right: -4px;
    }
}

.tab-pending-badge {
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 4px;
    right: 3px;
    z-index: 100;

    &.label {
        @extend %caption-font-400;
        margin-left: 4px;
        fill: $light-yellow;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            font-weight: 700;
        }
    }
}

.menu-item-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: -2px;
    margin-left: 4px;
    width: 20px;
    height: 20px;
    border: solid 1px $dark-green;
    border-radius: 50%;
    color: $dark-green;
    background: $light-green;
    @extend %caption-font-400;

    &.more {
        width: 21px;
        height: 21px;
    }
}

.circle-ellipse {
    fill: $light-yellow;
    stroke: $dark-green;
}
</style>
