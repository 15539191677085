<template>
    <span class="badge" :class="badgeClasses" :style="cssVars">
        <SvgIcon v-if="iconName" class="icon" :icon-id="iconName" :title="iconTooltip" />
        <span v-if="text" class="badge-text">{{ text }}</span>
    </span>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        text: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'default',
        },
        mode: {
            type: String,
            default: 'default',
        },
        iconName: {
            type: String,
            required: false,
        },
        bgColor: {
            type: String,
            require: false,
        },
        iconTooltip: {
            type: String,
            default: '',
        },
    },
    computed: {
        badgeClasses() {
            return [`type-${this.type}`, `mode-${this.mode}`];
        },
        cssVars() {
            return {
                '--badge-bg-color': this.bgColor || '',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

.badge-text {
    vertical-align: middle;
}

/* Modes */
.mode-circle {
    width: 18px;
    height: 18px;
    margin: 0 7px;
    vertical-align: text-bottom;
}

.mode-micro {
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 0 1px;
}

.mode-medium {
    width: 16px;
    height: 16px;
    margin: 0 0 0 8px;
    vertical-align: text-bottom;
}

.mode-locked {
    padding: 4px;
    font-size: 12px;
}

/* Types */
.type-win {
    background: $badge-win-background;
    color: $badge-win-color;
}

.type-lose {
    background: $badge-lose-background;
}

.type-cancelled {
    background: $badge-pending-background;
    padding: 0;

    .icon {
        margin: 0;
        width: 12px;
        height: 12px;
        fill: #aaaeb0;
    }
}

.type-pending {
    background: $badge-pending-background;
}

.type-pending-dark {
    background: $badge-pending-dark-background;
}

.type-new-messages {
    background: $badge-new-messages-background; // Fallback for presto
    background: var(--badge-bg-color, $badge-new-messages-background);
    border-radius: $badge-new-messages-border-radius;
    color: $white-text;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    padding: 3px 5px;
    margin-right: 4px;
}

.type-locked {
    background: $badge-locked-background;
    color: $badge-locked-color;
}

.type-new {
    background: $light-green; // Fallback for presto
    background: var(--badge-bg-color, $light-green);
    color: $main-text;
    font-size: 10px;
    line-height: 13px;
    font-style: italic;
    position: relative;
    padding: 0 3px;
    margin: 4px 10px 4px 0;
    :after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -6px;
        border-style: solid;
        border-width: 6px 0 7px 6px;
        border-color: transparent transparent transparent $light-green; // Fallback for presto
        border-color: transparent transparent transparent var(--badge-bg-color, $light-green);
    }
    .badge-text {
        vertical-align: inherit;
    }
}

.type-best-odds {
    vertical-align: middle;
    margin-right: 0;
    padding: 0;

    &:has(.badge-text) {
        background: #831aed;
        border-radius: 10px;
        font-size: 13px;
        line-height: 16px;
        color: white;
        padding: 1px 8px 1px 4px;
    }
    .badge-text {
        margin-left: 3px;
    }
    .icon {
        margin-right: 0;
        width: 18px;
        height: 18px;
    }
}
</style>
