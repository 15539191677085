/**
 * Price sorting strategy object maps sorting method (defined by BE)
 * to their corresponding sorting logic
 */
export const priceSortingStrategy = {
    DEFAULT: (a, b) => a.typeId - b.typeId,
    PRICE_TYPE_ID: (a, b) => a.typeId - b.typeId,
    GOALSCORER_SORTING: (a, b) => {
        // Check if either object matches the specific NO_GOALS_PRICE_ID
        if (a.position === 1) return 1; // a goes to the end
        if (b.position === 1) return -1; // b goes to the end

        // Check if either object is suspended
        if (a.suspended && !b.suspended) return 1; // a goes after b
        if (!a.suspended && b.suspended) return -1; // b goes after a

        // Sort by price in ascending order
        return a.price - b.price;
    },
};
